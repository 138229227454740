// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    width: 100%;
    background-color: #0d77b6 !important;
    height: 60px;
}

li::before {
    content: "•";
    color: black;
    display: inline-block;
}

.icons li {
    content: "\\2022";
    color: black;
    height: 7px;
    width: 7px;
    line-height: 0;
    list-style: none outside none;
    margin-right: 15px;
    margin-top: 3px;
    vertical-align: top;
    border-radius: 50%;
    pointer-events: none;
}

.dropbtn {
    padding-left: 12px;
    font-size: 20px;
    border-radius: 10px;
    border: 1px solid black;
}

.dropdown-content {
    display: none;
    position: relative;
    margin-top: 60px;
    background-color: #f9f9f9;
    min-width: 160px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown a:hover {
    background-color: #f1f1f1
}

.show {
    display: block;
}`, "",{"version":3,"sources":["webpack://./src/layouts/index.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,oCAAoC;IACpC,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,WAAW;IACX,UAAU;IACV,cAAc;IACd,6BAA6B;IAC7B,kBAAkB;IAClB,eAAe;IACf,mBAAmB;IACnB,kBAAkB;IAClB,oBAAoB;AACxB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,gBAAgB;IAChB,yBAAyB;IACzB,gBAAgB;IAChB,cAAc;IACd,+CAA+C;IAC/C,UAAU;AACd;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,qBAAqB;IACrB,cAAc;AAClB;;AAEA;IACI;AACJ;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".header {\n    width: 100%;\n    background-color: #0d77b6 !important;\n    height: 60px;\n}\n\nli::before {\n    content: \"•\";\n    color: black;\n    display: inline-block;\n}\n\n.icons li {\n    content: \"\\2022\";\n    color: black;\n    height: 7px;\n    width: 7px;\n    line-height: 0;\n    list-style: none outside none;\n    margin-right: 15px;\n    margin-top: 3px;\n    vertical-align: top;\n    border-radius: 50%;\n    pointer-events: none;\n}\n\n.dropbtn {\n    padding-left: 12px;\n    font-size: 20px;\n    border-radius: 10px;\n    border: 1px solid black;\n}\n\n.dropdown-content {\n    display: none;\n    position: relative;\n    margin-top: 60px;\n    background-color: #f9f9f9;\n    min-width: 160px;\n    overflow: auto;\n    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);\n    z-index: 1;\n}\n\n.dropdown-content a {\n    color: black;\n    padding: 12px 16px;\n    text-decoration: none;\n    display: block;\n}\n\n.dropdown a:hover {\n    background-color: #f1f1f1\n}\n\n.show {\n    display: block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
