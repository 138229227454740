import axios from "axios";
import { SERVER_URL } from "../consts";

class NetMedia {

  getNode() {
    return new Promise((resolve, reject) => {
      axios.post(`${SERVER_URL}/media`)
        .then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
    })
  }
  addNode(nodeData) {
    return new Promise((resolve, reject) => {
      axios.post(`${SERVER_URL}/media/add-node`, nodeData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      )
        .then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
    })
  }
  editNode(nodeData) {
    return new Promise((resolve, reject) => {
      axios.post(`${SERVER_URL}/media/edit-node-file`, nodeData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      )
        .then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
    })
  }
  editNode2(nodeData) {
    return new Promise((resolve, reject) => {
      axios.post(`${SERVER_URL}/media/edit-node`, nodeData)
        .then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
    })
  }
  deleteNode(data) {
    return new Promise((resolve, reject) => {
      axios.post(`${SERVER_URL}/media/delete-node`, data)
        .then(res => {
          resolve(res.data)
        })
        .catch(error => {
          reject(error)
        });
    })
  }
  joinNodes(data) {
    return new Promise((resolve, reject) => {
      axios.post(`${SERVER_URL}/media/join-node`, data)
        .then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
    })
  }
  addVideo1(data) { // custom file
    return new Promise((resolve, reject) => {
      axios.post(`${SERVER_URL}/media/add-video-file`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
    })
  }
  addVideo2(data) { // youtube url
    return new Promise((resolve, reject) => {
      axios.post(`${SERVER_URL}/media/add-video`, data)
        .then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
    })
  }
  getVideos(nid) {
    return new Promise((resolve, reject) => {
      axios.post(`${SERVER_URL}/media/videos`, { nodeId: nid })
        .then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
    })
  }
  deleteVideo(data) {
    return new Promise((resolve, reject) => {
      axios.post(`${SERVER_URL}/media/delete-video`, data)
        .then(res => {
          resolve(res.data)
        }).catch(error => reject(error));
    })
  }
  addLike(data) {
    return new Promise((resolve, reject) => {
      axios.post(`${SERVER_URL}/media/add-like`, data)
        .then(res => { resolve(res.data) })
        .catch(error => reject(error));
    })
  }
  getRequest() {
    return new Promise((resolve, reject) => {
      axios.post(`${SERVER_URL}/media/get-request`)
        .then(res => {
          resolve(res.data)
        }).catch(error => reject(error));
    })
  }
  addRequest(data) {
    return new Promise((resolve, reject) => {
      axios.post(`${SERVER_URL}/media/add-request`, data)
        .then(res => resolve(res.data))
        .catch(error => reject(error))
    })
  }
  addToken(data) {
    return new Promise((resolve, reject) => {
      axios.post(`${SERVER_URL}/media/add-token`, data)
        .then(res => resolve(res.data))
        .catch(error => reject(error))
    })
  }
  releaseTokens(data) {
    return new Promise((resolve, reject) => {
      axios.post(`${SERVER_URL}/media/release-token`, data)
        .then(res => resolve(res.data))
        .catch(error => reject(error))
    })
  }
  clear48HoursLater(data) {
    return new Promise((resolve, reject) => {
      axios.post(`${SERVER_URL}/media/clean48`, data)
        .then(res => resolve(res.data))
        .catch(error => reject(error))
    })
  }
}

export const netMediaApi = new NetMedia();
