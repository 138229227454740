import React from "react";
import { DefaultLayout } from "./layouts/DefaultLayout";
import { Home } from "./pages/Home";
import { Route, Routes } from "react-router-dom"; 
import { Marketplace } from "./pages/Marketplace";
import { StripeResult } from "./pages/StripeResult";
import { Profile } from "./pages/Profile";
import { ChangeName } from "./pages/ChangeName";
import { BeltRequest } from "./pages/BeltRequest";
import { Manage } from "./pages/Manage";
import { Pin } from "./pages/Pin";

export const Router = () => {
  return (
    <Routes>
      <Route path="" element={<DefaultLayout />}>
        <Route index element={<Home />} />
        <Route path="/stripe-result" element={<StripeResult />} />
        <Route path="/marketplace" element={<Marketplace />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/profile/name" element={<ChangeName />} />
        <Route path="/profile/request" element={<BeltRequest />} />
        <Route path="/profile/manage" element={<Manage />} />
        <Route path="/profile/pin" element={<Pin />} />
      </Route>
    </Routes>
  );
}
