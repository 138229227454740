import React, { useState } from "react";
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { login, log_out } from "../store/oauth";
import { oauthApi } from "../services/oauthApi";
import { WelcomeModal } from "../components/WelcomeModal";

import './index.css';

export const Navbar = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, user, loginWithRedirect, logout } = useAuth0();
  const userData = useSelector(state => state.oauth.user);

  const [welcomeVisible, setWelcomeVisible] = useState(false);

  const logoutHandler = () => {
    dispatch(log_out());
    logout({ logoutParams: { returnTo: window.location.origin } });
  }

  const showWelcomeModal = () => {
    localStorage['welcome'] = 'false';
    setWelcomeVisible(true);
  }
  const closeModal = () => {
    setWelcomeVisible(false);
  }

  React.useEffect(() => {
    if (user/* && !userData*/) {
      oauthApi.oauth(user).then(res => {
        dispatch(login(res));
      })
    }
  }, [user, dispatch]);

  const showDropdown = () => {
  }

  return (
    <div className="h-16" style={{ boxShadow: "5px 5px 5px 5px rgb(192, 191, 191)" }}>
      <div className="w-full container mx-auto flex justify-between h-full">
        <Link to="" className="../ cursor-pointer"><img src="logo-final.png" className="h-full" alt="" /></Link>
        <div className="h-full items-center flex flex-row gap-3 text-black">
          {isAuthenticated && userData ? (
            <>
              <Link to="/profile/name" className="text-decoration-none text-black">
                {userData.username != '' && userData.username != null ? userData.username : 'User'}
              </Link>
              <span>{userData.points}</span>
              <Link to="/profile">
                {userData.belt === 1 && <img className="h-6" src="/Belt_White.svg" alt="White Belt" />}
                {userData.belt === 2 && <img className="h-6" src="/Belt_Blue.svg" alt="Blue Belt" />}
                {userData.belt === 3 && <img className="h-6" src="/Belt_Purple.svg" alt="Purple Belt" />}
                {userData.belt === 4 && <img className="h-6" src="/Belt_Brown.svg" alt="Brown Belt" />}
                {userData.belt === 5 && <img className="h-6" src="/Belt_Black.svg" alt="Black Belt" />}
              </Link>
              {
                userData && userData.admin > 0 ?
                  <Link to="/profile/pin" className="text-black">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-pin-angle" viewBox="0 0 16 16">
                      <path d="M9.828.722a.5.5 0 0 1 .354.146l4.95 4.95a.5.5 0 0 1 0 .707c-.48.48-1.072.588-1.503.588-.177 0-.335-.018-.46-.039l-3.134 3.134a5.927 5.927 0 0 1 .16 1.013c.046.702-.032 1.687-.72 2.375a.5.5 0 0 1-.707 0l-2.829-2.828-3.182 3.182c-.195.195-1.219.902-1.414.707-.195-.195.512-1.22.707-1.414l3.182-3.182-2.828-2.829a.5.5 0 0 1 0-.707c.688-.688 1.673-.767 2.375-.72a5.922 5.922 0 0 1 1.013.16l3.134-3.133a2.772 2.772 0 0 1-.04-.461c0-.43.108-1.022.589-1.503a.5.5 0 0 1 .353-.146zm.122 2.112v-.002.002zm0-.002v.002a.5.5 0 0 1-.122.51L6.293 6.878a.5.5 0 0 1-.511.12H5.78l-.014-.004a4.507 4.507 0 0 0-.288-.076 4.922 4.922 0 0 0-.765-.116c-.422-.028-.836.008-1.175.15l5.51 5.509c.141-.34.177-.753.149-1.175a4.924 4.924 0 0 0-.192-1.054l-.004-.013v-.001a.5.5 0 0 1 .12-.512l3.536-3.535a.5.5 0 0 1 .532-.115l.096.022c.087.017.208.034.344.034.114 0 .23-.011.343-.04L9.927 2.028c-.029.113-.04.23-.04.343a1.779 1.779 0 0 0 .062.46z" />
                    </svg>
                  </Link> : <></>
              }
              {
                userData && userData.admin > 0 ?
                  <Link to="/profile/manage" className="text-black">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M14 6L8 12V16H12L18 10M14 6L17 3L21 7L18 10M14 6L18 10M10 4L4 4L4 20L20 20V14" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> </g>
                    </svg>
                  </Link> : <></>
              }
              {
                userData && userData.requests && userData.requests.length > 0 ?
                  <Link to="/profile/request" className="text-black">
                    <div style={{ padding: '5px', background: 'red', borderRadius: '5px', position: 'absolute', marginLeft: '12px', marginTop: '-3px' }}></div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
                    </svg>
                  </Link> : <></>
              }
              <button onClick={logoutHandler}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-out">
                  <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line>
                </svg>
              </button>
            </>
          ) : (
            <button onClick={loginWithRedirect} className="flex justify-center">
              Sign In&nbsp;
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-in">
                <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"></path><polyline points="10 17 15 12 10 7"></polyline><line x1="15" y1="12" x2="3" y2="12"></line>
              </svg>
            </button>
          )}

          <button onClick={showWelcomeModal} className='flex justify-center'>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-question-circle" viewBox="0 0 16 16"> <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" /> <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" /> </svg>
          </button>

          {welcomeVisible && <WelcomeModal visible={welcomeVisible} closeModal={closeModal} />}

          <div className="flex items-center">
            <div className="h-[32px] w-[32px] menu-item">
              <ul className="dropbtn icons" onClick={showDropdown}>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
            <div id="myDropdown" className="dropdown-content">
              <a href="#home">Go To</a>
              <a href="#about">About</a>
              <li className="divider"></li>
              <a href="#contact">Save as Draft</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}