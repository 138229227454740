import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { profileApi } from "../services/profile";
import { useDispatch, useSelector } from 'react-redux';
import { updatePoints, updateTotal } from '../store/oauth';
import Swal from 'sweetalert2';

import { VAULT_ADDRESS, TOKEN_ADDRESS } from '../consts';

import { Web3, ETH_DATA_FORMAT } from 'web3';
import BigNumber from 'bignumber.js';

import { useSDK } from '@metamask/sdk-react';

import ERC20_ABI from '../erc20-abi.json';

import '../index.css';

class GasHelper {
  static gasMulptiplier = 1.2 // Increase by 50%

  static gasPay(gasLimit) {
    return Math.ceil(Number(gasLimit) * GasHelper.gasMulptiplier).toString()
  }
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

Modal.setAppElement('#root');

export const DepositModal = (props) => {

  const { provider } = useSDK();

  const dispatch = useDispatch();

  const { user } = useSelector(state => state.oauth)

  const [maticPrice, setMaticPrice] = useState(0);
  const [tokenAmount, setTokenAmount] = useState(0);
  const [matic_balance, setMaticBalance] = useState(0);
  const [token_balance, setTokenBalance] = useState(0);

  const [error, setErrMsg] = useState('');

  const getMaticPrice = () => {
    axios.get('https://leaderboard.quickswap.exchange/utils/token-prices/v3?chainId=137&addresses=0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270')
      .then((res) => {
        setMaticPrice(res.data.data[0].price);
      })
      .catch(e => {
        setMaticPrice(0);
        console.log(e);
      })
  }

  useEffect(() => {
    getMaticPrice();

    const interval = setInterval(() => {
      getMaticPrice();
    }, 10000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {

    (async () => {

      // get block number
      var blockNumber = await window.ethereum.request({
        "method": "eth_blockNumber",
        "params": []
      });

      ///////////////////////////////
      // get Matic & Token Balance //
      ///////////////////////////////

      // matic
      var sBalance = await window.ethereum.request({
        "method": "eth_getBalance",
        "params": [
          props.account,
          blockNumber
        ]
      });

      setMaticBalance(
        BigNumber(sBalance)
          .div(BigNumber(10).pow(18))
          .toNumber().toFixed(6)
      );

      // token
      var web3 = new Web3(provider);

      const contract = new web3.eth.Contract(ERC20_ABI, TOKEN_ADDRESS);
      let result = await contract.methods.balanceOf(props.account).call();

      setTokenBalance(
        BigNumber(result)
          .div(BigNumber(10).pow(18))
          .toNumber()
      );

    })();
  });

  const closeModal = () => {
    props.closeModal();
  }

  const changeHandle = (e) => {
    if (e.target.value == '') {
      setTokenAmount(0);
    } else {
      setTokenAmount(parseInt(e.target.value));
    }
  }

  useEffect(() => {
    if (tokenAmount > token_balance || tokenAmount < 0) {
      setErrMsg('Retype the token amount.')
    } else {
      setErrMsg('')
    }
  }, [tokenAmount])

  const deposit = async () => {
    if (tokenAmount > token_balance || tokenAmount < 0) {
      return;
    }

    var web3 = new Web3(provider);

    const contract = new web3.eth.Contract(ERC20_ABI, TOKEN_ADDRESS);

    // Verify token symbol
    try {
      const symbol = await contract.methods.symbol().call()
      console.log(`Token symbol is: ${symbol}`)
    } catch (error) {
      console.error('Error while checking symbol')
      console.error(error)
    }

    var from = props.account;
    var to = VAULT_ADDRESS;
    try {
      const gasPrice = await web3.eth.getGasPrice(ETH_DATA_FORMAT)
      const gasLimit = await contract.methods.transfer(to, web3.utils.toWei(tokenAmount, 'ether'))
        .estimateGas({ from: from });

      const tx = await web3.eth.sendTransaction({
        from: from,
        to: TOKEN_ADDRESS,
        data: contract.methods.transfer(to, web3.utils.toWei(tokenAmount, 'ether')).encodeABI(),
        gasPrice: gasPrice,
        gas: GasHelper.gasPay(gasLimit),
      });

      console.log(`${tokenAmount} tokens transferred from address ${from} to address ${to} in transaction ${tx.transactionHash}`)

      // Check balance as address 0 and 1
      const balance0 = await contract.methods.balanceOf(from).call()
      console.log(`Balance of address 0 is: ${balance0}`)

      const balance1 = await contract.methods.balanceOf(to).call()
      console.log(`Balance of address 1 is: ${balance1}`)

      Swal.fire({
        title: `${tokenAmount} tokens are deposited successfully.`,
        icon: 'info'
      });

      profileApi.buyTokens({ id: user.id, tokens: tokenAmount })
        .then((data) => {
          console.log(data.points);

          dispatch(updateTotal({ total: user.total + (data.points - user.points) }))
          dispatch(updatePoints({ points: data.points }))
        }).catch((err) => {
          console.log(err);
        });

      closeModal();

    } catch (error) {
      console.error('Error while transferring tokens and checking balance')
      console.error(error)
    }
  }

  return (
    <div>
      <Modal
        isOpen={props.visible}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button onClick={closeModal} className='float-right text-2xl'>&times;</button>
        <p className='text-xl text-center mt-3'> Deposit </p>

        <div className='py-3 w-96 flex flex-col'>
          <div className="flex p-2 w-full justify-center mb-2 items-center gap-2 text-2xl">
            <img src='/matic-token.png' className='w-10'></img>
            = ${maticPrice.toFixed(6)}
          </div>
          <div className='text-bg-success px-2 py-1 text-lg text-center font-bold'>
            Balance
          </div>
          <div className='text-bg-success px-2 py-1 text-lg'>
            {matic_balance} MATIC
          </div>
          <div className='text-bg-success px-2 py-1 text-lg'>
            {token_balance} MATTOKEN
          </div>
          <div className="flex p-2 items-center text-bg-dark gap-2">
            <input type='number' min={0} step={1} name='tokens' onChange={changeHandle} className="flex w-full h-2/5 text-bg-dark" placeholder='Token Amount' />
            <div className='flex gap-1'>
              <div>=</div>
              <div>{tokenAmount}</div>
              <div>MATTOKEN</div>
            </div>
          </div>
          {
            error.length > 0 && (
              <div className='text-bg-danger px-2 py-1 text-center'>
                {error}
              </div>
            )
          }
          <button className="flex p-3 mt-5 mx-5 bg-green-400 rounded-lg justify-center" onClick={deposit}>Deposit</button>
        </div>
      </Modal>
    </div>
  );
}