export const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export const REACT_APP_AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
export const REACT_APP_AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;

export const TOKEN_ADDRESS = '0xC3AC0c33f68D1076381e8E3415Ea2D64EE702641'; // Token address
export const VAULT_ADDRESS = '0x19219ED8A8Adf72F0F00cF7aA5915F67900dB9Be'; // Vault address
export const VAULT_PRIV_KEY = process.env.REACT_APP_0VP_KEY; // Vault Private Key

export const PRICE_LOOKUP_KEY = 'price_1O1y6eGxw4Ljii2NSSTQ24ch';

export const RATE = 10; // Price Rate (Real value is 10)
export const SUBSCRIPTION_PRICE = 5; // 5 USD

export const belt_json = ['Black', 'Brown', 'Purple', 'Blue', 'White'];
export const bg_color = ['', 'rgba(207, 191, 176, 0.4)', 'rgba(213, 227, 228, 0.4)', 'rgba(217, 217, 217, 0.4)'];
