import React from 'react';
import ReactPlayer from 'react-player';
import { useAuth0 } from '@auth0/auth0-react';
import Swal from 'sweetalert2';
import { netMediaApi } from "../services/netMedia";
import { useDispatch, useSelector } from "react-redux";
import { get_media } from "../store/media";
import { updatePoints } from '../store/oauth';

export const Video = (props) => {
  const { isAuthenticated } = useAuth0();

  const dispatch = useDispatch();
  const { user } = useSelector(state => state.oauth)

  const deleteHandle = () => {
    Swal.fire({
      title: 'Do you want to delete this video?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {

        netMediaApi.deleteVideo({
          id: props.data.id,
          nodeId: props.data.nodeId
        }).then(res => {
          props.handleList(res.videos);
        })
      }
    })
  }

  const likeHandle = () => {
    Swal.fire({
      title: 'Do you want to add like to this video?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        netMediaApi.addLike({
          userid: user.id,
          belt: user.belt,
          media_id: props.data.id,
        }).then(res => {

          if (res.success) {

            console.log(res);

            dispatch(get_media({ medias: res.net_media }))
            dispatch(updatePoints({ points: res.user.points }))
            props.setAllNode(res.net_media);

            const url = new URL(window.location.href);
            const result_key = url.searchParams.get('rk');
            if (result_key) {
              const results = result_key.split(',')
              const id = results[results.length - 1]
              for (var i = 0; i < res.net_media.length; i++) {
                if (res.net_media[i].id == id) {
                  props.clickMedia(res.net_media[i], 2)
                  break
                }
              }
            }
          } else {
            // error
            Swal.fire({
              title: res.msg,
              icon: 'error',
            })
          }
        })
      }
    })
  }

  return (
    <div className="col-sm-12 col-md-6 col-lg-4 gap-4">
      {
        props.data.url.indexOf('youtube') >= 0 ?
          <ReactPlayer url={props.data.url} width='100%' /> :
          <video width="100%" height="390" controls>
            <source src={props.data.url} type="video/mp4" />
            <source src={props.data.url} type="video/webm" />
            Your browser does not support the video tag.
          </video>
      }

      <div className='flex justify-center my-2'>
        {props.data.title}
      </div>

      <div className='flex justify-end cursor-pointer my-2'>
        {/* Like */}
        {
          isAuthenticated && user &&
          < div onClick={likeHandle} className='flex mr-3'>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" strokeWidth="2" stroke="currentColor" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <path d="M7 11v 8a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-7a1 1 0 0 1 1 -1h3a4 4 0 0 0 4 -4v-1a2 2 0 0 1 4 0v5h3a2 2 0 0 1 2 2l-1 5a2 3 0 0 1 -2 2h-7a3 3 0 0 1 -3 -3" /></svg>
            <label>{props.data.likes}</label>
          </div>
        }

        {/* Trash */}
        {
          isAuthenticated && user && user.admin > 0 &&
          <div onClick={deleteHandle}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">  <polyline points="3 6 5 6 21 6" />  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />  <line x1="10" y1="11" x2="10" y2="17" />  <line x1="14" y1="11" x2="14" y2="17" /></svg>
          </div>
        }
      </div>
    </div >
  )
}