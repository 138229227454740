import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import { netMediaApi } from '../services/netMedia';
import Swal from 'sweetalert2';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

export const WelcomeModal = (props) => {

  const [visible, setVisible] = useState(false);

  const closeModal = () => {
    props.closeModal();
    localStorage['welcome'] = 'true';
  }
  const handleOkay = () => {
    closeModal();
  }

  useEffect(() => {
    if (localStorage['welcome'] != 'true') {
      setVisible(true);
    }
  }, [props])

  return (
    <div>
      <Modal
        isOpen={visible}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div>
          <button onClick={closeModal} className='float-right text-2xl'>&times;</button>
          <p className='text-xl text-center mt-3'> Welcome! </p>

          <div className="w-96 max-h-[360px] overflow-auto border-1 border-gray-300 p-1">
            <p>&emsp;Welcome to TheBJJMatrix.com - Your Gateway to the Ultimate Brazilian Jiu Jitsu Experience!</p>
            <p>&emsp;🥋 Dive into the world of Brazilian Jiu Jitsu and grappling techniques with TheBJJMatrix.com, your premier Web3 app. Here's how it works:</p>
            <p>&emsp;🌐 Explore a Matrix of Techniques: Our unique clickable matrix consists of nodes, each representing a specific technique. Easily navigate through the top section to start your search, explore the middle section to see techniques leading to your selection, and find ideas of what techniques to do next in the bottom section.</p>
            <p>&emsp;📺 Access Exclusive Videos: Once you select a technique, discover a treasure trove of instructional videos at the bottom of the page. Learn from experts and enhance your BJJ skills.</p>
            <p>&emsp;🚀 Empower Content Creation: Can't find a technique you're looking for? No problem! You have the power to create new nodes. Request videos from the community or specific @users, and even incentivize them with MATTOKENs for their contributions.</p>
            <p>&emsp;💰 Powered by MATTOKEN: Our ecosystem thrives on MATTOKEN, a digital currency similar to in-game currencies like Robux or Reddit's Moon. Subscribe for just $5/month or pay with 50 MATTOKENs to access premium content and support your favorite creators.</p>
            <p>&emsp;🛒 MATTOKEN Marketplace: Boost your MATTOKEN collection by purchasing from our marketplace, often at a discount. MATTOKEN can be used to tip content creators, unlocking additional engagement with their content.</p>
            <p>&emsp;🔐 Secure Your MATTOKEN: We've simplified crypto management. Your MATTOKEN is securely stored in a Vault on TheBJJMatrix.com, making it easy to buy, sell, and trade within our platform. If you wish to venture outside, follow our instructions to export your tokens to a crypto wallet.</p>
            <p>&emsp;💡 Economic Transparency: Our ecosystem started with 100M MATTOKEN, with 10M allocated to the reward pool and 90M for future growth. Every new user signup generates 50 new MATTOKENs, all directed to fuel the reward pool and expand our thriving community.</p>
            <p>&emsp;Join us in revolutionizing the Brazilian Jiu Jitsu world. Unlock a wealth of knowledge, support your favorite creators, and embrace the power of Web3 with TheBJJMatrix.com today!</p>
          </div>

          <div className='flex justify-center'>
            <button className="px-5 py-3 mt-4 bg-green-400 rounded-lg" onClick={handleOkay}>OK</button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
