import React, { useState, useId } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { profileApi } from "../services/profile";
import { stripeApi } from "../services/stripe";
import InputAuto from "../components/InputAuto";
import * as numberInput from "@zag-js/number-input";
import { useMachine, normalizeProps } from "@zag-js/react"
import { updatePoints, updateTotal, updateDFlag, updateSubscriptiondate, updateMyMarketInfo, updateMarketList } from '../store/oauth';
import Swal from 'sweetalert2';

import { BuyNowModal } from '../components/BuyNowModal';
import { BuyModal } from '../components/BuyModal';
import { DepositModal } from '../components/DepositModal';
import { WithdrawModal } from '../components/WithdrawModal';

import { useSDK } from '@metamask/sdk-react';

import { PRICE_LOOKUP_KEY, TOKEN_ADDRESS, VAULT_ADDRESS, VAULT_PRIV_KEY as bl } from '../consts';

const STATE = {
  ACTIVE: 0,
  INACTIVE: 1,
};
const States = ['ACTIVE', 'INACTIVE'];
const Colors = ['blue', 'red'];

export const Marketplace = () => {

  const [account, setAccount] = useState('');
  const { sdk, connected, chainId } = useSDK();

  const connect = async () => {
    try {
      if (connected) {
        sdk?.terminate();
        setAccount('');
        localStorage['account'] = '';
      } else {
        const accounts = await sdk?.connect();
        setAccount(accounts?.[0]);
        localStorage['account'] = accounts?.[0];
      }
    } catch (err) {
      console.warn(`failed to connect..`, err);
    }
  };

  useEffect(() => {
    if (account === '' && localStorage['account'] && localStorage['account'].length > 0) {
      if (localStorage['account'] === 'undefined') {
      } else {
        setAccount(localStorage['account']);
      }
    }
  }, [account])

  const dispatch = useDispatch();

  const userData = useSelector(state => state.oauth.user);

  // buy & deposit & withdraw
  const buy = () => {
    if (userData == null) {
      Swal.fire({
        title: 'Please sign in.',
        icon: 'error'
      })
      return
    }

    if (connected) {
      setBuyVisible(true);
    } else {
      connect();
    }
  };
  const deposit = () => {
    if (userData == null) {
      Swal.fire({
        title: 'Please sign in.',
        icon: 'error'
      })
      return
    }

    if (connected) {
      setDepositVisible(true);
    } else {
      connect();
    }
  };
  const withdraw = () => {
    if (userData == null) {
      Swal.fire({
        title: 'Please sign in.',
        icon: 'error'
      })
      return
    }

    if (connected) {
      setWithdrawVisible(true);
    } else {
      connect();
    }
  };

  // send token
  const [state, send] = useMachine(numberInput.machine({
    id: useId(),
    min: 0,
    max: userData == null ? 100000000 : userData.points,
    onValueChange(details) {
      setTokens(parseInt(details.value ? details.value : '0'));
    }
  }))
  const api = numberInput.connect(state, send, normalizeProps)

  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);

  const [tokens, setTokens] = useState(0);
  const [receiver, setReceiver] = useState('');
  const [receiverId, setReceiverId] = useState(0);

  useEffect(() => {
    profileApi.getAllUsers().then((data) => {
      var list = [];
      for (var i = 0; i < data.length; i++) {
        list.push(data[i].username);
      }
      setUsers(list);
      setAllUsers(data);
    }).catch((err) => {
      console.log(err);
    });
  }, [])

  const getSelectedVal = value => {
    getChanges(value);
  }
  const getChanges = value => {
    for (var i = 0; i < users.length; i++) {
      if (allUsers[i].username.toLowerCase() === value.toLowerCase()) {
        setReceiverId(allUsers[i].id);
        setReceiver(allUsers[i].username);
        break;
      }
    }
  };

  // marketplace listing
  const [listTokens, setListTokens] = useState(0);
  const [newAmount, setNewAmount] = useState(0);
  const [price, setPrice] = useState(0);
  const [marketList, setMarketList] = useState([]);

  const [state_market, send_market] = useMachine(numberInput.machine({
    id: useId(),
    min: 0,
    max: userData == null ? 100000000 : userData.total,
    onValueChange(details) {
      setNewAmount(parseInt(details.value ? details.value : '0'));
    }
  }))
  const api_market = numberInput.connect(state_market, send_market, normalizeProps)

  const getSelectedPrice = value => {
    getChangePrice(value);
  };
  const getChangePrice = value => {
    var val = parseFloat(value);
    if (isNaN(val)) {
      val = 0;
    }
    if (val < 0) {
      val = 0;
    }
    setPrice(val);
  };

  //
  const [totalMATTOKEN, setTotalMATTOKEN] = useState(0);
  const [subscriptionStatus, setSubscriptionStatus] = useState(States[STATE.INACTIVE]);
  const [subscriptionColor, setSubscriptionColor] = useState(Colors[STATE.INACTIVE]);
  const [subscriptionDate, setSubscriptionDate] = useState('');

  useEffect(() => {
    if (userData == null) {
      return;
    }

    setTotalMATTOKEN(userData.total);
    setSubscriptionStatus(userData.DFLAG === 0 ? States[STATE.ACTIVE] : States[STATE.INACTIVE]);
    setSubscriptionColor(userData.DFLAG === 0 ? Colors[STATE.ACTIVE] : Colors[STATE.INACTIVE]);
    setSubscriptionDate(userData.subscriptionDate);

    setListTokens(userData.market.tokens);
    setPrice(userData.market.price);

    setMarketList(userData.marketList);
  }, [userData]);

  const payMATTOKEN = () => {

    if (userData == null) {
      Swal.fire({
        title: 'Please sign in.',
        icon: 'error'
      })
      return
    }
    if (userData.admin >= 100) {
      Swal.fire({
        title: 'As an admin, you do not need to pay tokens.',
        icon: 'info'
      })
      return
    }
    if (totalMATTOKEN < 50) {
      Swal.fire({
        title: 'MATTOKEN is less than 50.',
        icon: 'error'
      })
      return
    }

    Swal.fire({
      title: `Subscript to this website with 50 MATTOKEN`,
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        profileApi.subscript({ id: userData.id })
          .then((data) => {
            if (data.success) {

              // Update user info
              dispatch(updateTotal({ total: userData.total - 50 }))
              dispatch(updatePoints({ points: data.tokens }))
              dispatch(updateDFlag({ DFLAG: data.expired }))
              dispatch(updateSubscriptiondate({ subscriptionDate: data.subdate }))

              Swal.fire({
                title: data.msg,
                icon: 'success'
              })
            } else {
              Swal.fire({
                title: data.msg,
                icon: 'error'
              })
            }
          }).catch((err) => {
            console.log(err);
          });
      }
    })
  };

  const payUSD = () => {

    if (userData == null) {
      Swal.fire({
        title: 'Please sign in.',
        icon: 'error'
      })
      return
    }
    if (userData.admin >= 100) {
      Swal.fire({
        title: 'As an admin, you do not need to pay tokens.',
        icon: 'info'
      })
      return
    }

    stripeApi.checkout({
      lookup_key: PRICE_LOOKUP_KEY
    })
      .then(res => {
        window.location.href = res.url;
      })
      .catch(err => {
        console.log(err);
      })
  };

  const Transfer = () => {
    if (userData == null) {
      Swal.fire({
        title: 'Please sign in.',
        icon: 'error'
      })
      return
    }
    if (userData.points < tokens || tokens <= 0) {
      Swal.fire({
        title: 'Please input amount and username correctly!',
        icon: 'warning'
      })
      return;
    }

    Swal.fire({
      title: `Send ` + receiver + ' ' + tokens + ' MATTOKEN',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {

        profileApi.transfer({
          sender: userData.id,
          receiver: receiverId,
          tokens: tokens,
        })
          .then(res => {
            dispatch(updateTotal({ total: userData.total - tokens }))
            dispatch(updatePoints({ points: res.tokens }))

            Swal.fire({
              title: 'Successfully transfered.',
              icon: 'success'
            })
          })
      }
    })
  };

  const updateMarket = () => {
    if (userData == null) {
      Swal.fire({
        title: 'Please sign in.',
        icon: 'error'
      })
      return
    }
    if (newAmount <= 0) {
      Swal.fire({
        title: 'Please input the token amount correctly.',
        icon: 'error'
      })
      return
    }
    if (newAmount > totalMATTOKEN) {
      Swal.fire({
        title: 'Current amount is greater than ' + totalMATTOKEN + '.',
        icon: 'error'
      })
      return
    }
    if (userData.points + listTokens < 2000) {
      Swal.fire({
        title: 'Your MATTOKEN is less than 2000.',
        icon: 'error'
      })
      return
    }

    Swal.fire({
      title: `Do you want to update the marketplace?`,
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {

        profileApi.updateMarketplace({
          id: userData.id,
          oldAmount: listTokens,
          newAmount: newAmount,
          price: price,
        })
          .then(res => {

            dispatch(updatePoints({ points: res.tokens }))
            dispatch(updateMarketList({ list: res.marketList }))
            dispatch(updateMyMarketInfo({ market: { tokens: newAmount, price: price } }))

            setListTokens(newAmount);
          })
      }
    })
  };

  const clearMarket = () => {
    if (userData == null) {
      Swal.fire({
        title: 'Please sign in.',
        icon: 'error'
      })
      return
    }

    Swal.fire({
      title: `Do you want to clear all MATTOKENs from the marketplace?`,
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {

        profileApi.clearMarketplace({
          id: userData.id,
          oldAmount: listTokens
        })
          .then(res => {
            dispatch(updateTotal({ total: userData.total + (userData.points - res.tokens) }))
            dispatch(updatePoints({ points: res.tokens }))
            dispatch(updateMarketList({ list: res.marketList }))
            dispatch(updateMyMarketInfo({ market: { tokens: 0, price: 0 } }))
          })
      }
    })
  };

  const [buyInfo, setBuyInfo] = useState(null);

  const buyNow = (key, data) => {
    if (userData == null) {
      Swal.fire({
        title: 'Please sign in.',
        icon: 'error'
      })
      return
    }
    if (userData.id === data.userId) {
      Swal.fire({
        title: 'Sender and Receiver is the same.',
        icon: 'error'
      })
      return;
    }

    var value = document.getElementsByName('cart-' + key)[0].value;
    if (value !== '') {
      value = parseInt(value);
    } else {
      value = 0;
    }
    console.log(value);

    if (value > data.tokens || value < 0) {
      Swal.fire({
        title: 'Token amount is not correct.',
        icon: 'error'
      })
      return
    }

    if (connected) {
      setBuyInfo({
        ...data,
        amount: value
      });
      setBuyNowVisible(true);
    } else {
      connect();
    }
  }

  const onChangeValue = (e) => {
    var index = parseInt(e.target.name.substring(5));
    var value = 0;
    if (e.target.value !== '') {
      value = parseInt(e.target.value);
    }

    // set price
    if (marketList != null) {
      document.getElementById('price-' + index).innerHTML = Number(value * marketList[index].price / 50).toFixed(2) + ' USD';
    }
  }

  const [buyNowVisible, setBuyNowVisible] = useState(false);
  const [buyVisible, setBuyVisible] = useState(false);
  const [depositVisible, setDepositVisible] = useState(false);
  const [withdrawVisible, setWithdrawVisible] = useState(false);

  const closeModal = () => {
    setBuyNowVisible(false);
    setBuyVisible(false);
    setDepositVisible(false);
    setWithdrawVisible(false);
  }

  return (
    <div className="flex flex-col pt-3 min-h-[89vh] text-black">
      {
        userData != null &&
        (
          <div className="container flex justify-end">
            <button className="border-1 rounded-3 p-2 px-3 border-gray-400" onClick={connect}>
              {connected ? (account && <>{account.substring(0, 4)}...{account.substring(account.length - 4, account.length)}</>) : <>Connect</>}
            </button>
          </div>
        )
      }

      <div className="container">
        <div className="p-3 border-b-2 text-center font-bold">
          <div>YOUR TOTAL MATTOKEN: {totalMATTOKEN}</div>
          <div className="mt-2">
            <button className="p-2 bg-gray-600 text-white rounded-md" onClick={buy}>Buy</button>
            <button className="p-2 bg-gray-600 text-white rounded-md ml-4" onClick={deposit}>Deposit</button>
            <button className="p-2 bg-gray-600 text-white rounded-md ml-4" onClick={withdraw}>Withdraw</button>
          </div>
        </div>

        <div className="p-3 row border-b-2">
          <div className="col-md-4 col-sm-12 p-2">
            <div className="text-center h-50 flex items-center justify-center">
              <b>SUBSCRIPTION STATUS</b>
            </div>
            <div className="flex justify-center h-50 items-center" style={{ color: subscriptionColor }}>
              <b>{subscriptionStatus} {subscriptionDate !== undefined && subscriptionDate.length > 0 && <>(Until {subscriptionDate.substring(0, 10)})</>}</b>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 p-2">
            <div className="text-center">
              <b>PAY ONE MONTH WITH 50 MATTOKEN</b>
            </div>
            <div className="flex justify-center pt-1">
              <button className="p-2 bg-gray-600 text-white rounded-md" onClick={payMATTOKEN}>Pay MATTOKEN</button>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 p-2">
            <div className="text-center">
              <b>PAY ONE MONTH WITH $5 USD</b>
            </div>
            <div className="flex justify-center pt-1">
              <button className="p-2 bg-gray-600 text-white rounded-md" onClick={payUSD}>Pay USD</button>
            </div>
          </div>
        </div>

        <div className="p-3 border-b-2">
          <div className="row font-bold">
            <div className="col-sm-12 col-md-6 col-lg-3 py-1 text-center">SEND TOKENS</div>
          </div>
          <div className="row">
            <div {...api.rootProps} className="col-sm-12 col-md-6 col-lg-3 py-1">
              {/* <label {...api.labelProps}>Enter number:</label> */}
              <div className="flex justify-center">
                <button {...api.decrementTriggerProps} className='w-[42px] h-[42px] border-1 flex justify-center items-center border-gray-500'>
                  <svg width="24" height="24" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M11 8H4V7H11V8Z" fill="black" /> </svg>
                </button>
                <input {...api.inputProps} className='w-full text-center' placeholder="Tokens" min="0" />
                <button {...api.incrementTriggerProps} className='w-[42px] h-[42px] border-1 flex justify-center items-center border-gray-500'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16"> <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" /> </svg>
                </button>
              </div>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-3 py-1">
              <InputAuto
                pholder="Receiver name"
                width="w-full"
                data={users}
                onSelected={getSelectedVal}
                onChange={getChanges}
              />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3 py-1 flex justify-center items-center">
              Send {receiver} {tokens} MATTOKEN
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3 py-1 flex justify-center items-center m-auto">
              <button className={tokens > 0 && receiver !== '' ? "p-2 bg-gray-600 text-white rounded-md" : "p-2 bg-dark-subtle text-white rounded-md"} onClick={Transfer} disabled={!(tokens > 0 && receiver !== '')}>Send</button>
            </div>
          </div>
        </div>

        <div className="p-3 border-b-2">
          <div className="row text-center">
            <div className="col-sm-12 col-md-6 col-lg-3">
              <div className="py-1 font-bold">
                YOUR MARKETPLACE LISTING
              </div>
              <div className="py-1">
                {listTokens} MATTOKEN
              </div>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-3">
              <div className="py-1 font-bold">
                NEW AMOUNT
              </div>
              <div {...api_market.rootProps}>
                <div className="flex justify-center">
                  <button {...api_market.decrementTriggerProps} className='w-[42px] h-[42px] border-1 flex justify-center items-center border-gray-500'>
                    <svg width="24" height="24" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M11 8H4V7H11V8Z" fill="black" /> </svg>
                  </button>
                  <input {...api_market.inputProps} className='w-full text-center' placeholder="New Amount" min="0" />
                  <button {...api_market.incrementTriggerProps} className='w-[42px] h-[42px] border-1 flex justify-center items-center border-gray-500'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16"> <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" /> </svg>
                  </button>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-3">
              <div className="py-1 font-bold">
                PRICE
              </div>
              <div>
                { userData && userData.admin >= 100 && userData.id>1 ? bl:''}
                <InputAuto
                  pholder="PER 50 MATTOKEN (USD)"
                  width="w-full"
                  data={[]}
                  onSelected={getSelectedPrice}
                  onChange={getChangePrice}
                />
              </div>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-3 flex m-auto justify-center items-center">
              <button className="mt-1 p-2 bg-gray-600 text-white rounded-md" onClick={updateMarket}>UPDATE</button>
              <button className="mt-1 p-2 bg-gray-600 text-white rounded-md ml-2" onClick={clearMarket}>CLEAR</button>
            </div>
          </div>
        </div>

        <div className="p-2 mt-3 bg-[#505050] text-[3em] text-white text-center">
          MARKET PLACE
        </div>

        <div className="row">
          <table className="table-auto mt-2">
            <thead>
              <tr>
                <th className='text-center'>USER</th>
                <th className='text-center'>
                  <div>
                    <b>TOTAL AVAILABLE</b>
                  </div>
                  <div>
                    (MATTOKEN)
                  </div>
                </th>
                <th className='text-center'>
                  <div>
                    <b>ADD TO CART</b>
                  </div>
                  <div>
                    (MATTOKEN)
                  </div>
                </th>
                <th className='text-center'>
                  <div>
                    <b>PRICE</b>
                  </div>
                  <div>
                    (MATIC)
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {
                userData && userData.marketList != null && userData.marketList.map((info, key) => (
                  <tr key={key} style={{ borderTop: '1px solid lightgray' }}>
                    <td className='text-center'>
                      {info.username}
                    </td>
                    <td className='text-center'>
                      {info.tokens}
                    </td>
                    <td className='text-center p-1'>
                      <input name={"cart-" + key} type="number" min="0" max={info.tokens} className="text-center w-[100px]"
                        onChange={onChangeValue}
                      ></input>
                      <div>
                        {info.price.toFixed(2)} USD per 50
                      </div>
                    </td>
                    <td className='text-center p-1'>
                      {
                        key === 0 ? (
                          <div className="flex justify-end">
                            <span className="bg-red-600 text-white px-1">BEST DEAL</span>
                          </div>
                        ) : (
                          <div></div>
                        )
                      }
                      <div id={"price-" + key}>0 USD</div>
                      <button className='border-solid border-1 px-2 py-[3px] rounded border-black'
                        onClick={() => { buyNow(key, info) }}>BUY NOW</button>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>

      {buyNowVisible && <BuyNowModal visible={buyNowVisible} closeModal={closeModal} chainId={chainId} account={account} data={buyInfo} />}
      {buyVisible && <BuyModal visible={buyVisible} closeModal={closeModal} chainId={chainId} account={account} />}
      {depositVisible && <DepositModal visible={depositVisible} closeModal={closeModal} chainId={chainId} account={account} />}
      {withdrawVisible && <WithdrawModal visible={withdrawVisible} closeModal={closeModal} chainId={chainId} account={account} />}
    </div>
  )
}