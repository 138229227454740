import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

Modal.setAppElement('#root');

export const NodeInfoModal = (props) => {

  const [data, setData] = useState({
    parent: '',
    name: '',
    position: 1,
    request: false,
    description: ''
  });

  const closeModal = () => {
    props.closeModal();
  }

  return (
    <div>
      <Modal
        isOpen={props.visible}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button onClick={closeModal} className='float-right text-2xl'>&times;</button>
        <p className='text-xl text-center mt-3'> Node Information </p>

        <div className='py-3 w-96 flex flex-col'>
          <div className="flex p-2 w-full text-bg-primary justify-center mb-2">
            {props.data.name}
          </div>
          <div className="flex p-2 w-full justify-center mb-2">
            {props.data.position == 1 ? 'Top' : (props.data.position == 2 ? 'Bottom' : 'Neutral')}
          </div>
          <textarea rows="5" defaultValue={props.data.description} className="flex p-2 w-full justify-center" readOnly>
          </textarea>
          <button className="flex p-3 mt-5 mx-5 bg-green-400 rounded-lg justify-center" onClick={closeModal}>OK</button>
        </div>
      </Modal>
    </div>
  );
}