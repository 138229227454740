import React, { useState } from "react";
import Select from 'react-select';
import { belt_json } from "../consts";
import { useSelector } from "react-redux";
import { profileApi } from "../services/profile";

export const Manage = () => {

  const userData = useSelector(state => state.oauth.user);

  const [users, setUsers] = useState([])
  const [option, setOption] = useState([])
  const [belt, setBelt] = useState([])
  const [checked, setChecked] = useState([])
  const [keyword, setKeyword] = useState('')

  React.useEffect(() => {
    profileApi.getUsers().then((data) => {
      setUsers(data);

      const belts = [];
      const check = [];
      for (var i = 0; i < data.length; i++) {
        belts.push(userData.belt - data[i].belt);
        check.push(data[i].admin > 0);
      }
      setBelt(belts);
      setChecked(check);
    }).catch((err) => {
      console.log(err);
    });

    const op = [];
    for (let i in belt_json) {
      if (5 - i <= userData.belt) {
        op.push({ value: 5 - i, image: `/Belt_${belt_json[i]}.svg` });
      }
    }
    setOption(op);
  }, []);

  const clickCheckBox = (e) => {
    checked[parseInt(e.target.name)] = e.target.checked;
    setChecked(checked);
  }

  const handleClick = (data, index) => {
    const requestBelt = userData.belt - belt[index];
    if (data.email !== userData.email) {
      if (userData.belt < requestBelt || data.belt > userData.belt) {
        console.log('Belt: too high')
        return
      }

      // console.log(requestBelt, index, checked[index]);

      profileApi.resetBeltAndAdmin(data.email, requestBelt, userData.id, checked[index]).then((data) => {
        if (data.success == true) {
          console.log(data.msg);
          // toast.success
        } else {
          console.log(data.msg);
          // toast.error
        }
      }).catch((err) => {
        console.log(err);
      });

      // data, requestBelt
    } else {
      console.log('Email: SAME')
    }
  }

  const searchUser = (e) => {
    setKeyword(e.target.value)
  }

  return (
    <div className="flex flex-col pt-3 min-h-[89vh] text-black" key={2}>
      <div className="container">
        <div className="flex justify-end mb-1">
          <input type="text" placeholder="Search Users" value={keyword} className="rounded-md mr-2" onChange={searchUser} />
        </div>
        <div className="flex flex-col">
          {
            userData && (
              users.length == 0 ? <div className="flex justify-center">No records</div> :
                <table className="table-auto">
                  <thead>
                    <tr className="border-1">
                      <th className="w-[200px] text-center">Username</th>
                      <th className="w-[100px] text-center">Belt</th>
                      <th className="w-[80px] text-center">Admin</th>
                      <th className="w-[100px] text-center">Reset</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((v, k) => (
                      v.belt > userData.belt || v.username.toLowerCase().indexOf(keyword.toLowerCase()) < 0 ?
                        <></> :
                        <tr key={k} className="border-1">
                          <td className="text-center">
                            {v.username}
                          </td>
                          <td>
                            <div className="w-full flex justify-center">
                              <Select
                                value={option[belt[k]]}
                                defaultValue={option[belt[k]]}
                                className="w-28 bg-gray-300 focus:border-none"
                                options={option}
                                isSearchable={false}
                                onChange={(e) => {
                                  belt[k] = userData.belt - e.value;
                                  setBelt(belt)
                                }}
                                formatOptionLabel={op => {
                                  return (
                                    <div className="py-1 flex justify-center">
                                      <img src={op.image} alt="no-image" className={`h-6`} />
                                    </div>
                                  )
                                }}
                              />
                            </div>
                          </td>
                          <td className="text-center">
                            <input type="checkbox" name={k} onChange={(e) => clickCheckBox(e)} defaultChecked={checked[k]}></input>
                          </td>
                          <td>
                            <div className="w-full flex justify-center">
                              <button className="p-2 bg-gray-600 text-white rounded-md" onClick={() => handleClick(v, k)}>OK</button>
                            </div>
                          </td>
                        </tr>
                    ))}
                  </tbody>
                </table>
            )
          }
        </div>
      </div>
    </div>
  )
}