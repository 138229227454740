import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { netMediaApi } from '../services/netMedia';
import { profileApi } from "../services/profile";
import { useDispatch, useSelector } from 'react-redux';
import { updatePoints } from '../store/oauth';
import { get_request } from '../store/media';
import Swal from 'sweetalert2';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

export const RequestModal = (props) => {

  const dispatch = useDispatch();
  const { user } = useSelector(state => state.oauth)

  const [data, setData] = useState({
    media_id: props.defaultValue,
    public: 1,
    userid: 0,
    tokens: 0,
    requester: user.id
  });

  const [users, setUsers] = useState([]);

  const closeModal = () => {
    props.closeModal();
  }
  const addRequest = () => {
    if (data.media_id == 0) {
      Swal.fire({
        title: 'Please select node.',
        icon: 'info'
      });
      return;
    }

    if (data.public == 0 && data.tokens == 0) {
      Swal.fire({
        title: 'Please input token amount.',
        icon: 'info'
      });
      return;
    }

    if (data.public == 0) {
      if (data.tokens > user.points) {
        Swal.fire({
          title: `Total points: ${user.points}`,
          text: 'Please input the value again.',
          icon: 'error'
        });
        return
      }
    }

    netMediaApi.addRequest(data)
      .then(res => {
        console.log('Escrowed points=', res.points);

        if (res.points > 0) {
          // Update user info
          dispatch(updatePoints({ points: res.points }))
        }

        netMediaApi.getRequest().then(res => {
          dispatch(get_request({ requests: res.requests }))
        })
        props.closeModal();
      })
  }
  const changeHandle = (e) => {
    setData({
      ...data,
      [e.target.name]: parseInt(e.target.value != '' ? e.target.value : '0')
    })
  }
  const setPublic = (val) => {
    var tokens = data.tokens;
    var userid = data.userid;
    if (val == 1) {
      userid = 0;
      tokens = 0;
    }

    setData({
      ...data,
      public: val,
      userid: userid,
      tokens: tokens
    })
  }

  useEffect(() => {
    profileApi.getAllUsers().then((data) => {
      setUsers(data);
    }).catch((err) => {
      console.log(err);
    });

    if (props.userid != user.id) {
      setPublic(0)
    }
  }, [])

  return (
    <div>
      <Modal
        isOpen={props.visible}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div>
          <button onClick={closeModal} className='float-right text-2xl'>&times;</button>
          <p className='text-xl text-center mt-3'>
            Add Request
          </p>

          <div className='py-5 w-96 flex flex-col'>
            <div className="flex p-2 w-full text-bg-primary justify-center mb-3">
              {props.node}
            </div>

            {
              props.userid == user.id &&
              <div className='w-full flex justify-between px-12'>
                <div><input type='radio' name='public' onClick={() => setPublic(1)} defaultChecked={data.public === 1} className="relative t-3" /> Public</div>
                <div><input type='radio' name='public' onClick={() => setPublic(0)} defaultChecked={data.public === 0} className="relative t-3" /> User</div>
              </div>
            }

            {
              data.public == 0 ?
                <>
                  <div>Users:</div>
                  <div className="flex p-2 w-full">
                    <select type='text' name='userid' onChange={changeHandle} className="flex w-full h-2/5 border-1px">
                      <option value={0} key={0}></option>
                      {users.map((item, key) => (
                        item.id != user.id ? <option value={item.id} key={key}>{item.username}</option> : <></>
                      ))}
                    </select>
                  </div>
                  <div>Tokens:</div>
                  <div className="flex p-2">
                    <input type='text' name='tokens' defaultValue={data.tokens == 0 ? '' : data.tokens} onChange={changeHandle} className="flex w-full h-2/5" placeholder='Token amount' />
                  </div>
                </> :
                <div className="flex p-2 justify-center"> <div className="flex items-center" style={{ height: '39px' }}>Free</div></div>
            }

            <button onClick={addRequest} className="flex p-3 mt-5 mx-5 bg-green-400 rounded-lg justify-center">Request</button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
