import { useEffect, useState } from "react"
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from "react-redux";
import { bg_color } from "../consts";
import { netMediaApi } from "../services/netMedia";
import Swal from 'sweetalert2';
import { EditNodeModal } from "../components/EditNodeModal";
import { NodeInfoModal } from "../components/NodeInfoModal";

export const Media = (props) => {
  const { isAuthenticated } = useAuth0();

  const { user } = useSelector(state => state.oauth)

  const [selectNode, setSelectNode] = useState();
  const [editNodeVisible, setEditNodeVisible] = useState(false);
  const [infoVisible, setInfoVisible] = useState(false);

  const [like, setLike] = useState(0);

  const handleContextMenu = (e) => {
    e.preventDefault();
    setInfoVisible(true)
  }

  useEffect(() => {
    setSelectNode(props.data);
    setLike(props.data.likes ?? 0);
  }, [props]);

  const editHandle = () => {
    setEditNodeVisible(true);
  }
  const deleteHandle = () => {
    Swal.fire({
      title: 'Do you want to delete this node?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {

        netMediaApi.deleteNode({ node_id: props.data.id })
          .then(res => {
            props.reload(res.net_media);
          })
      }
    })
  }

  const clickMedia = (e) => {
    props.clickMedia(props.data, props.type)
  }

  const closeModal = () => {
    setEditNodeVisible(false);
    setInfoVisible(false);
  }

  return (
    <div className={`flex flex-col cursor-pointer w-[200px]`} key={props.data.id} onClick={e => e.stopPropagation()} >
      <div className="text-5 text-center relative top-7 text-lg"
        style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '200px' }}>
        {props.data.name}
      </div>
      <div className="text-5 text-center relative top-7 text-lg"
        style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '200px' }}>
        ({props.data.username})
      </div>

      {/* Image */}
      <div onClick={clickMedia} className='mt-4 w-[200px] h-[200px] flex justify-center items-center' onContextMenu={handleContextMenu}
        style={{
          clipPath: 'circle()',
          background: `${bg_color[props.data.position]}`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className='w-[160px] h-[160px]' onContextMenu={handleContextMenu}
          style={{
            clipPath: 'circle()',
            backgroundImage: `url(${props.data.image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
        </div>
      </div>

      <div className="flex flex-row justify-center gap-2">
        {/* Likes */}
        <div className="flex flex-row">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" strokeWidth="2" stroke="currentColor" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <path d="M7 11v 8a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-7a1 1 0 0 1 1 -1h3a4 4 0 0 0 4 -4v-1a2 2 0 0 1 4 0v5h3a2 2 0 0 1 2 2l-1 5a2 3 0 0 1 -2 2h-7a3 3 0 0 1 -3 -3" /></svg>
          <label>{like}</label>
        </div>

        {/* Edit */}
        {
          isAuthenticated && user && (user.admin > 0 || user.id == props.data.userid) &&
          <div onClick={editHandle} className="flex flex-row">
            <svg width="24" height="24" viewBox="0 0 24 24"> <g> <path fill="none" d="M0 0h24v24H0z" /> <path d="M21 6.757l-2 2V4h-9v5H5v11h14v-2.757l2-2v5.765a.993.993 0 0 1-.993.992H3.993A1 1 0 0 1 3 20.993V8l6.003-6h10.995C20.55 2 21 2.455 21 2.992v3.765zm.778 2.05l1.414 1.415L15.414 18l-1.416-.002.002-1.412 7.778-7.778z" /> </g> </svg>
          </div>
        }

        {/* Trash */}
        {
          isAuthenticated && user && user.admin > 0 &&
          <div onClick={deleteHandle} className="flex flex-row">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" strokeWidth="2" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">  <polyline points="3 6 5 6 21 6" />  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />  <line x1="10" y1="11" x2="10" y2="17" />  <line x1="14" y1="11" x2="14" y2="17" /></svg>
          </div>
        }
      </div>
      <div>
        {editNodeVisible && <EditNodeModal visible={editNodeVisible} closeModal={closeModal} setAllNode={props.setAllNode} clickMedia={props.clickMedia} data={selectNode} />}
        {infoVisible && <NodeInfoModal visible={infoVisible} closeModal={closeModal} data={props.data} />}
      </div>
    </div>
  )
}
