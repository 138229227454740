import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import { PersistGate } from "redux-persist/lib/integration/react"
import { persistor, store } from "./store"
import { Auth0Provider } from '@auth0/auth0-react';

import { REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_CLIENT_ID } from "./consts";

export const Wrapper = ({ children }) => {

    return (
        <Auth0Provider
            domain={REACT_APP_AUTH0_DOMAIN}
            clientId={REACT_APP_AUTH0_CLIENT_ID}
            audience="https://api-endpoint"
            authorizationParams={{
                redirect_uri: window.location.origin
            }}
        >
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <BrowserRouter>
                        {children}
                    </BrowserRouter>
                </PersistGate>
            </Provider>
        </Auth0Provider>
    )
}