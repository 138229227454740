import React, { useState } from "react";

export default function InputAuto({
  pholder,
  data,
  width,
  onSelected,
  onChange
}) {
  const [suggestions, setSugesstions] = useState([]);
  const [isHideSuggs, setIsHideSuggs] = useState(true);
  const [selectedVal, setSelectedVal] = useState("");

  const handler = e => {
    setSugesstions(data.filter(i => i.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0));
  };

  const handleChange = e => {
    const input = e.target.value;
    setIsHideSuggs(e.target.value.length == 0);
    setSelectedVal(input);
    onChange(input);
  };

  const hideSuggs = value => {
    onSelected(value);
    setSelectedVal(value);
    setIsHideSuggs(true);
  };

  return (
    <div className="sugesstion-auto w-full">
      <div className="form-control-auto w-full">
        <input
          className={width}
          placeholder={pholder}
          type="search"
          value={selectedVal}
          onChange={handleChange}
          onKeyUp={handler}
        />
      </div>

      <div
        className="max-h-[100px] overflow-auto suggestions"
        style={{ display: isHideSuggs ? "none" : "block", border: '1px solid lightgray' }}
      >
        {suggestions.map((item, idx) => (
          <div
            className="p-1"
            style={{borderBottom: "1px solid lightgray"}}
            key={"" + item + idx}
            onClick={() => {
              hideSuggs(item);
            }}
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  );
}
