import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { profileApi } from "../services/profile";
import { useDispatch, useSelector } from 'react-redux';
import { updatePoints, updateTotal } from '../store/oauth';

import { RATE, VAULT_ADDRESS } from '../consts';
import BigNumber from 'bignumber.js';

import '../index.css';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

Modal.setAppElement('#root');

export const BuyModal = (props) => {

  const dispatch = useDispatch();

  const { user } = useSelector(state => state.oauth)

  const [maticPrice, setMaticPrice] = useState(0);
  const [maticAmount, setMaticAmount] = useState(0);
  const [tokenAmount, setTokenAmount] = useState(0);
  const [balance, setBalance] = useState(0);

  const [error, setErrMsg] = useState('');

  const getMaticPrice = () => {
    axios.get('https://leaderboard.quickswap.exchange/utils/token-prices/v3?chainId=137&addresses=0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270')
      .then((res) => {
        setMaticPrice(res.data.data[0].price);
      })
      .catch(e => {
        setMaticPrice(0);
        console.log(e);
      })
  }

  useEffect(() => {
    getMaticPrice();

    const interval = setInterval(() => {
      getMaticPrice();
    }, 10000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {

    (async () => {

      // get block number
      var blockNumber = await window.ethereum.request({
        "method": "eth_blockNumber",
        "params": []
      });

      var sBalance = await window.ethereum.request({
        "method": "eth_getBalance",
        "params": [
          props.account,
          blockNumber
        ]
      });

      setBalance(
        BigNumber(sBalance)
          .div(BigNumber(10).pow(18))
          .toNumber().toFixed(6)
      );

    })();
  });

  const closeModal = () => {
    props.closeModal();
  }

  const changeHandle = (e) => {
    if (e.target.value == '') {
      setMaticAmount(0);
    } else {
      setMaticAmount(parseInt(e.target.value) / RATE / maticPrice);
      setTokenAmount(parseInt(e.target.value));
    }
  }

  useEffect(() => {
    if (balance < maticAmount || maticAmount < 0) {
      setErrMsg('Retype the token amount.')
    } else {
      setErrMsg('')
    }
  }, [maticAmount])

  const buyTokens = () => {
    if (balance < maticAmount || maticAmount < 0) {
      return;
    }

    window.ethereum
      .request({
        method: 'eth_sendTransaction',
        params: [
          {
            from: props.account,
            to: VAULT_ADDRESS,
            value: '0x' + BigNumber(maticAmount).multipliedBy(BigNumber(10).pow(18)).toString(16),
            gasLimit: '0x5028',
            maxPriorityFeePerGas: '0x3b9aca00',
            maxFeePerGas: '0x2540be400',
          },
        ],
      })
      .then((txHash) => {
        console.log('txHash', txHash)
        console.log('token: ', tokenAmount);

        // receive tokens
        profileApi.buyTokens({ id: user.id, tokens: tokenAmount })
          .then((data) => {
            console.log(data.points);

            dispatch(updateTotal({ total: user.total + (data.points - user.points) }))
            dispatch(updatePoints({ points: data.points }))
          }).catch((err) => {
            console.log(err);
          });

        closeModal();
      })
      .catch((error) => console.error(error));
  }

  return (
    <div>
      <Modal
        isOpen={props.visible}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button onClick={closeModal} className='float-right text-2xl'>&times;</button>
        <p className='text-xl text-center mt-3'> BUY MATTOKEN </p>

        <div className='py-3 w-96 flex flex-col'>
          <div className="flex p-2 w-full justify-center mb-2 items-center gap-2 text-2xl">
            <img src='/matic-token.png' className='w-10'></img>
            = ${maticPrice.toFixed(6)}
          </div>
          <div className='text-bg-success px-2 py-1 text-lg'>
            <b>Balance</b>: {balance - maticAmount} MATIC
          </div>
          <div className="flex p-2 items-center text-bg-dark gap-2">
            <input type='number' min={0} step={1} name='tokens' onChange={changeHandle} className="flex w-full h-2/5 text-bg-dark" placeholder='Token Amount' />
            <div className='flex gap-1'>
              <div>=</div>
              <div>{maticAmount.toFixed(6)}</div>
              <div>MATIC</div>
            </div>
          </div>
          {
            error.length > 0 && (
              <div className='text-bg-danger px-2 py-1 text-center'>
                {error}
              </div>
            )
          }
          <button className="flex p-3 mt-5 mx-5 bg-green-400 rounded-lg justify-center" onClick={buyTokens}>BUY</button>
        </div>
      </Modal>
    </div>
  );
}