import axios from "axios";
import { SERVER_URL } from "../consts";

class Profile {
  changeName(email, username) {
    return new Promise((resolve, reject) => {
      axios.post(`${SERVER_URL}/profile/change-name`, { email, username })
        .then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
    });
  }
  changeBelt(email, belt, verifierEmail) {
    return new Promise((resolve, reject) => {
      axios.post(`${SERVER_URL}/profile/change-belt`, { email, belt, verifierEmail })
        .then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
    });
  }
  resetBeltAndAdmin(email, belt, verifierId, admin) {
    return new Promise((resolve, reject) => {
      axios.post(`${SERVER_URL}/profile/reset-belt`, { email, belt, verifierId, admin })
        .then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
    });
  }
  getVerifiers(belt) {
    return new Promise((resolve, reject) => {
      axios.post(`${SERVER_URL}/profile/verifiers`, { belt })
        .then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
    });
  }
  verifyUser(req) {
    return new Promise((resolve, reject) => {
      axios.post(`${SERVER_URL}/profile/verify-user`, req)
        .then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
    });
  }
  getPinned() {
    return new Promise((resolve, reject) => {
      axios.post(`${SERVER_URL}/profile/get-pinned`)
        .then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
    });
  }
  getUsers() {
    return new Promise((resolve, reject) => {
      axios.post(`${SERVER_URL}/profile/get-users`)
        .then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
    });
  }
  getAllUsers() {
    return new Promise((resolve, reject) => {
      axios.get(`${SERVER_URL}/profile/globalUsers`)
        .then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
    });
  }
  setRecentData(data) {
    return new Promise((resolve, reject) => {
      axios.post(`${SERVER_URL}/profile/set-recent`, data)
        .then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
    });
  }

  // For marketplace
  getListTokens(data) {
    return new Promise((resolve, reject) => {
      axios.post(`${SERVER_URL}/profile/get-list-tokens`, data)
        .then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
    });
  }
  subscript(data) {
    return new Promise((resolve, reject) => {
      axios.post(`${SERVER_URL}/profile/subscript`, data)
        .then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
    });
  }
  transfer(data) {
    return new Promise((resolve, reject) => {
      axios.post(`${SERVER_URL}/profile/transfer`, data)
        .then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
    });
  }
  updateMarketplace(data) {
    return new Promise((resolve, reject) => {
      axios.post(`${SERVER_URL}/profile/update-market`, data)
        .then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
    });
  }
  clearMarketplace(data) {
    return new Promise((resolve, reject) => {
      axios.post(`${SERVER_URL}/profile/clear-market`, data)
        .then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
    });
  }
  buyTokens(data) {
    return new Promise((resolve, reject) => {
      axios.post(`${SERVER_URL}/profile/buy-token`, data)
        .then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
    });
  }
  buyTokensFromMK(data) {
    return new Promise((resolve, reject) => {
      axios.post(`${SERVER_URL}/profile/buy-token-mk`, data)
        .then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
    });
  }
  withdraw(data) {
    return new Promise((resolve, reject) => {
      axios.post(`${SERVER_URL}/profile/withdraw`, data)
        .then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
    });
  }
}

export const profileApi = new Profile();
