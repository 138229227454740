import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { profileApi } from "../services/profile";
import { useDispatch, useSelector } from 'react-redux';
import { updatePoints, updateTotal } from '../store/oauth';
import { Web3, ETH_DATA_FORMAT } from 'web3';

import Swal from 'sweetalert2';

import { useSDK } from '@metamask/sdk-react';
import ERC20_ABI from '../erc20-abi.json';

import { TOKEN_ADDRESS, VAULT_ADDRESS, VAULT_PRIV_KEY } from '../consts';

import '../index.css';

class GasHelper {
  static gasMulptiplier = 1.2 // Increase by 50%

  static gasPay(gasLimit) {
    return Math.ceil(Number(gasLimit) * GasHelper.gasMulptiplier).toString()
  }
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

Modal.setAppElement('#root');

export const WithdrawModal = (props) => {

  const { provider } = useSDK();

  const dispatch = useDispatch();

  const { user } = useSelector(state => state.oauth)

  const [maticPrice, setMaticPrice] = useState(0);
  const [tokenAmount, setTokenAmount] = useState(0);

  const [error, setErrMsg] = useState('');

  const getMaticPrice = () => {
    axios.get('https://leaderboard.quickswap.exchange/utils/token-prices/v3?chainId=137&addresses=0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270')
      .then((res) => {
        setMaticPrice(res.data.data[0].price);
      })
      .catch(e => {
        setMaticPrice(0);
        console.log(e);
      })
  }

  useEffect(() => {
    getMaticPrice();

    const interval = setInterval(() => {
      getMaticPrice();
    }, 10000)

    return () => clearInterval(interval)
  }, [])

  const closeModal = () => {
    props.closeModal();
  }

  const changeHandle = (e) => {
    if (e.target.value == '') {
      setTokenAmount(0);
    } else {
      setTokenAmount(parseInt(e.target.value));
    }
  }

  useEffect(() => {
    if (tokenAmount > user.points || tokenAmount < 0) {
      setErrMsg('Retype the token amount.')
    } else {
      setErrMsg('')
    }
  }, [tokenAmount])

  const withdraw = async () => {
    if (tokenAmount > user.points || tokenAmount < 0) {
      return;
    }

    var web3 = new Web3(provider);

    const contract = new web3.eth.Contract(ERC20_ABI, TOKEN_ADDRESS);

    // Verify token symbol
    try {
      const symbol = await contract.methods.symbol().call()
      console.log(`Token symbol is: ${symbol}`)
    } catch (error) {
      console.error('Error while checking symbol')
      console.error(error)
    }

    var from = VAULT_ADDRESS;
    var to = props.account;
    try {
      const gasPrice = await web3.eth.getGasPrice(ETH_DATA_FORMAT)
      const gasLimit = await contract.methods.transfer(to, web3.utils.toWei(tokenAmount, 'ether'))
        .estimateGas({ from: from });

      var txParams = {
        from: from,
        to: TOKEN_ADDRESS,
        data: contract.methods.transfer(to, web3.utils.toWei(tokenAmount, 'ether')).encodeABI(),
        gasPrice: gasPrice,
        gas: GasHelper.gasPay(gasLimit),
      };

      const signedTx = await web3.eth.accounts.signTransaction(
        txParams,
        VAULT_PRIV_KEY // private key
      );

      web3.eth.sendSignedTransaction(signedTx.rawTransaction)
        .on('transactionHash', () => {

          (async () => {

            console.log(`${tokenAmount} tokens transferred from address ${from} to address ${to} in transaction ${signedTx.transactionHash}`)

            // Check balance as address 0 and 1
            const balance0 = await contract.methods.balanceOf(from).call()
            console.log(`Balance of address 0 is: ${balance0}`)

            const balance1 = await contract.methods.balanceOf(to).call()
            console.log(`Balance of address 1 is: ${balance1}`)

            Swal.fire({
              title: `${tokenAmount} tokens are withdrawed successfully.`,
              icon: 'info'
            });

            profileApi.withdraw({ id: user.id, tokens: tokenAmount })
              .then((data) => {
                dispatch(updateTotal({ total: user.total + (data.points - user.points) }))
                dispatch(updatePoints({ points: data.points }))
              }).catch((err) => {
                console.log(err);
              });

            closeModal();

          })();

        })
        .on('error', () => {

          Swal.fire({
            title: `You're failed to withdraw tokens.`,
            icon: 'error'
          });

        })

    } catch (error) {
      console.error('Error while transferring tokens and checking balance')
      console.error(error)
    }
  }

  return (
    <div>
      <Modal
        isOpen={props.visible}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button onClick={closeModal} className='float-right text-2xl'>&times;</button>
        <p className='text-xl text-center mt-3'> Withdraw </p>

        <div className='py-3 w-96 flex flex-col'>
          <div className="flex p-2 w-full justify-center mb-2 items-center gap-2 text-2xl">
            <img src='/matic-token.png' className='w-10'></img>
            = ${maticPrice.toFixed(6)}
          </div>

          <div className='text-bg-success px-2 py-1 text-lg'>
            <b>Balance</b>: {user.points} MATTOKEN
          </div>
          <div className="flex p-2 items-center text-bg-dark gap-2">
            <input type='number' min={0} step={1} name='tokens' onChange={changeHandle} className="flex w-full h-2/5 text-bg-dark" placeholder='Token Amount' />
            <div className='flex'>
              <div>MATTOKEN</div>
            </div>
          </div>
          {
            error.length > 0 && (
              <div className='text-bg-danger px-2 py-1 text-center'>
                {error}
              </div>
            )
          }
          <button className="flex p-3 mt-5 mx-5 bg-green-400 rounded-lg justify-center" onClick={withdraw}>Withdraw</button>
        </div>
      </Modal>
    </div>
  );
}