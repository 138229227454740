import axios from 'axios';
import { SERVER_URL } from '../consts';

class OAuthApi {
  oauth(userData) {
    return new Promise((resolve, reject) => {
      axios.post(`${SERVER_URL}/signIn`, userData).then(res => {
        resolve(res.data);
      }).catch(err => {
        reject(err);
      })
    })
  }
}

export const oauthApi = new OAuthApi();
