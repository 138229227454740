import React, { useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from "react-redux";
import { netMediaApi } from '../services/netMedia';
import { updatePoints } from '../store/oauth';
import { get_request } from '../store/media';
import Swal from 'sweetalert2';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

export const AddTokenModal = (props) => {

  const dispatch = useDispatch();

  const { user } = useSelector(state => state.oauth)

  const [tokens, setTokens] = useState('');

  const closeModal = () => {
    props.closeModal();
  }

  const onFormSubmit = (e) => {
    e.preventDefault();

    if (tokens !== '') {
      var tokenValue = parseInt(tokens);

      if (user.points < tokenValue) {
        Swal.fire({
          title: `Total points: ${user.points}`,
          text: 'Please input the value again.',
          icon: 'error'
        })
        return
      }

      netMediaApi
        .addToken({ ...props.node, userid: user.id, addedToken: tokenValue })
        .then(res => {
          if (res.points > 0) {
            // Update user info
            dispatch(updatePoints({ points: res.points }))
          }

          netMediaApi.getRequest().then(res => {
            dispatch(get_request({ requests: res.requests }))
          })

          props.closeModal();
        });
    }
    else {
      Swal.fire({
        title: 'Please input value.',
        icon: 'error'
      });
    }
  }
  const changeHandle = (e) => {
    setTokens(e.target.value)
  }

  return (
    <div>
      <Modal
        isOpen={props.visible}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div>
          <button onClick={closeModal} className='float-right text-2xl'>&times;</button>
          <p className='text-xl text-center mt-3'> Add Tokens </p>
          <form className='py-3 mt-5 w-96 flex flex-col' onSubmit={onFormSubmit}>
            <div className="flex p-2 w-full text-bg-primary justify-center mb-3">
              {props.node.name}
            </div>
            <div className='w-full flex justify-between px-3'>
              Token Amount:
            </div>
            <div className="flex p-2">
              <input type='text' name='token' onChange={changeHandle} className="flex w-full h-2/5" />
            </div>
            <button className="flex p-3 mt-5 mx-5 bg-green-400 rounded-lg justify-center">Save</button>
          </form>
        </div>
      </Modal>
    </div>
  );
}
