import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { netMediaApi } from '../services/netMedia';
import { useDispatch, useSelector } from 'react-redux';
import { get_media } from '../store/media';
import { updatePoints } from '../store/oauth';
import Swal from 'sweetalert2';
import InputAuto from "./InputAuto";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

Modal.setAppElement('#root');

export const AddNodeModal = (props) => {
  const dispatch = new useDispatch();

  const { user } = useSelector(state => state.oauth)
  const { medias } = useSelector(state => state.media)

  const [data, setData] = useState({
    parent: '',
    name: '',
    position: 1,
    request: false,
    description: ''
  });
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [branchMode, setBranchMode] = useState(false);
  const [nodeNames, setNodeNames] = useState([]);
  const [removable, setRemovable] = useState(false);

  const closeModal = () => {
    if (loading) {
      return;
    }
    props.closeModal();
  }

  useEffect(() => {
    props.defaultValue && setData({ ...data, parent: props.defaultValue });
  }, [props.defaultValue]);

  useEffect(() => {
    const url = new URL(window.location.href);
    const result_key = url.searchParams.get('rk');
    if (result_key) {
      const results = result_key.split(',');
      const id = results[results.length - 1]
      for (var i = 0; i < medias.length; i++) {
        if (medias[i].id == id) {
          props.clickMedia(medias[i], 1)
          break
        }
      }
    }

    var list = [];
    for (var i = 0; i < medias.length; i++) {
      list.push(medias[i].name);
    }
    setNodeNames(list);
  }, [medias])

  const onFormSubmit = (e) => {
    e.preventDefault();

    if (branchMode) {

      for (var i = 0; i < medias.length; i++) {
        if (medias[i].name.toLowerCase() == data.name.toLowerCase()) {
          if (data.parent) {
            console.log(data.parent)
            console.log(medias[i].id)

            netMediaApi.joinNodes({
              parent: data.parent,
              child: medias[i].id
            }).then(res => {
              if (res) {
                dispatch(get_media({ medias: res.net_media }))
                props.closeModal()
                props.setAllNode(res.net_media);

                const url = new URL(window.location.href);
                const result_key = url.searchParams.get('rk');
                if (result_key) {
                  const results = result_key.split(',');
                  const id = results[results.length - 1]
                  for (var i = 0; i < res.net_media.length; i++) {
                    if (res.net_media[i].id == id) {
                      // console.log('in', res.net_media[i])
                      props.clickMedia(res.net_media[i], 1)
                      break
                    }
                  }
                }
              } else {
                // err
              }
            })
          } else {
            Swal.fire({
              title: 'Please select parent node.',
              icon: 'info'
            })
          }
          return;
        }
      }

      return
    } else {
      for (var i = 0; i < medias.length; i++) {
        if (medias[i].name.toLowerCase() == data.name.toLowerCase()) {
          Swal.fire({
            title: 'Node name is duplicated!',
            icon: 'info'
          })
          return
        }
      }
    }

    if (image == null) {
      Swal.fire({
        title: 'Please select file!',
        icon: 'info'
      });
      return;
    }

    if (data.name !== '') {
      let formData = new FormData();
      formData.append("parent", data.parent);
      formData.append("name", data.name);
      formData.append('userid', user ? user.id : 0)
      formData.append('belt', user ? user.belt : 0)
      formData.append("image", image);
      formData.append("position", data.position);
      formData.append("request", data.request);
      formData.append("description", data.description);

      setLoading(true)
      netMediaApi.addNode(formData).then(res => {
        if (res) {
          console.log(res.user.points);
          dispatch(get_media({ medias: res.net_media }))
          dispatch(updatePoints({ points: res.user.points }))
          props.closeModal()
          setLoading(false)
        } else {
          // err
        }
      })
    }
    else {
      Swal.fire({
        title: 'Please input value.',
        icon: 'error'
      });
    }
  }
  const changeHandle = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    })

    setRemovable(false);

    if (e.target.name == 'parent') {
      if (e.target.value == '') {
        setBranchMode(false);
        return
      }

      for (var i = 0; i < medias.length; i++) {
        if (medias[i].name.toLowerCase() == data.name.toLowerCase()) {
          if (e.target.value == medias[i].id) {
            break
          }

          if (user.admin == 100) {
            // if admin

            for (var j = 0; j < medias.length; j++) {
              if (medias[j].id == e.target.value) {
                for (var k = 0; k < medias[j].children.length; k++) {
                  if (medias[j].children[k].name == data.name) {
                    setRemovable(true);
                    break;
                  }
                }
                break
              }
            }
          }

          setBranchMode(true);
          return
        }
      }

      setBranchMode(false);
    }
  }
  const handleCheckboxChange = (event) => {
    setData({ ...data, request: event.target.checked });
  }

  const getSelectedVal = value => {
    getChanges(value);
  }
  const getChanges = value => {
    setData({
      ...data,
      ['name']: value
    })

    setRemovable(false);

    if (data.parent == '') {
      setBranchMode(false);
      return
    }

    for (var i = 0; i < medias.length; i++) {
      if (medias[i].name.toLowerCase() == value.toLowerCase()) {
        if (data.parent == medias[i].id) {
          break
        }

        if (user.admin == 100) {
          // if admin

          for (var j = 0; j < medias.length; j++) {
            if (medias[j].id == data.parent) {
              console.log(medias[j])
              for (var k = 0; k < medias[j].children.length; k++) {
                if (medias[j].children[k].name == value) {
                  setRemovable(true);
                  break;
                }
              }
              break
            }
          }
        }

        setBranchMode(true);
        return
      }
    }

    setBranchMode(false);
  };

  return (
    <div>
      <Modal
        isOpen={props.visible}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        {loading ?
          <div className='flex justify-center items-center'>Uploading...</div> :
          <div>
            <button onClick={closeModal} className='float-right text-2xl'>&times;</button>
            <p className='text-xl text-center mt-3'>
              {branchMode ? (removable ? 'Delete Branch' : 'Connect Two Nodes') : 'New Node'}
            </p>
            <form className='py-3 w-96 flex flex-col' method="POST" onSubmit={onFormSubmit}>
              <div className="flex p-2 w-full">
                <select type='text' name='parent' defaultValue={props.defaultValue} onChange={changeHandle} className="flex w-full h-2/5 border-1px">
                  <option value={''} key={0}></option>
                  {medias !== undefined && medias.map((item, key) => (
                    <option value={item.id} key={key}>{item.name}</option>
                  ))}
                </select>
              </div>
              <div className="flex p-2">
                <InputAuto
                  pholder="Node"
                  width="w-full"
                  data={nodeNames}
                  onSelected={getSelectedVal}
                  onChange={getChanges}
                />
              </div>

              {
                branchMode ?
                  <div className='p-2'>
                    This node has already been created. Do you want to create a new branch between these nodes instead?
                  </div> :
                  <>
                    <div className="flex p-2">
                      <select type='text' name='position' defaultValue={data.position} onChange={changeHandle} className="flex w-full h-2/5">
                        <option value={1} key={1}>Top</option>
                        <option value={2} key={2}>Bottom</option>
                        <option value={3} key={3}>Neutral</option>
                      </select>
                    </div>
                    <div className="flex p-2 w-full">
                      <input type='file' name='image' accept='image/*' onChange={e => setImage(e.target.files[0])} className="flex w-full h-2/5" placeholder='Image' />
                    </div>
                    <div className='flex p-2 w-full gap-3'>
                      <input type='checkbox' className='mt-2' checked={data.request} onChange={handleCheckboxChange} /> Requirement
                    </div>
                    {
                      data.request &&
                      <div className="flex p-2 w-full">
                        <textarea name='description' onChange={changeHandle} className="flex w-full h-32" placeholder='Enter Description' />
                      </div>
                    }
                  </>
              }

              <button className="flex p-3 mt-5 mx-5 bg-green-400 rounded-lg justify-center">Save</button>
            </form>
          </div>}
      </Modal>
    </div>
  );
}