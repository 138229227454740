import React, { useState } from "react";
import { useSelector } from "react-redux";
import { profileApi } from "../services/profile";
import Swal from 'sweetalert2';

export const Pin = () => {

  const { medias } = useSelector(state => state.media)

  const [recData, setRecData] = useState([])
  const [checked, setChecked] = useState([])
  const [mdata, setMData] = useState([])
  const [keyword, setKeyword] = useState('')

  const MAX_SIZE = 12

  React.useEffect(() => {
    profileApi.getPinned().then((data) => {
      for (var i = data.length; i < MAX_SIZE; i++) {
        data[i] = { nodeId: 0 };
      }
      const check = {};
      for (i = 0; i < data.length; i++) {
        for (var j = 0; j < medias.length; j++) {
          check[medias[j].id] = false;
          if (medias[j].id === data[i].nodeId) {
            data[i].name = medias[j].name;
            data[i].target = document.getElementsByClassName('check-' + data[i].nodeId)[0];
            data[i].target.checked = true;
            check[medias[j].id] = true;
          }
        }
      }
      setRecData(data) // recent data
      setChecked(check) // check info
    }).catch((err) => {
      console.log(err);
    });

    setMData(medias)
  }, [medias]);

  const clickCheckBox = (e) => {
    setChecked({
      ...checked,
      [e.target.name]: e.target.checked
    })

    if (e.target.checked) {
      for (var i = 0; i < MAX_SIZE; i++) {
        if (recData[i].nodeId === 0) {
          recData[i].nodeId = e.target.name;
          recData[i].name = e.target.getAttribute('node');
          recData[i].target = e.target;
          break
        }
      }
    } else {
      for (i = 0; i < MAX_SIZE; i++) {
        if (recData[i].nodeId === e.target.name) {
          for (var j = i; j < MAX_SIZE - 1; j++) {
            recData[j] = recData[j + 1];
          }
          recData[MAX_SIZE - 1] = { nodeId: 0 };
          break
        }
      }
    }
  }

  const deselectItem = (e) => {
    const id = parseInt(e.target.name);

    setChecked({
      ...checked,
      [parseInt(recData[id].nodeId)]: false
    })

    recData[id].target.checked = false;

    for (var j = id; j < MAX_SIZE - 1; j++) {
      recData[j] = recData[j + 1];
    }
    recData[MAX_SIZE - 1] = { nodeId: 0 };
  }

  const searchUser = (e) => {
    setKeyword(e.target.value)
  }

  const saveItems = () => {
    const data = [];
    for (var i = 0; i < MAX_SIZE; i ++) {
      if (recData[i].nodeId === 0) {
        break;
      }

      data.push({
        id: i + 1,
        nodeId: parseInt(recData[i].nodeId)
      })
    }

    profileApi.setRecentData(data).then((data) => {
      Swal.fire({
        title: 'Saved successfully!',
        icon: 'info'
      })
    }).catch((e) => {
      console.log(e);
    });
  }

  return (
    <div className="flex flex-col pt-3 min-h-[89vh] text-black" key={2}>
      <div className="container">
        <div className="flex justify-end mb-1">
          <button className="bg-green-400 flex py-2 px-3 rounded-lg mr-2" onClick={saveItems}>Save</button>
        </div>
        <div className="flex flex-col">
          <table className="table-auto">
            <thead>
              <tr className="border-1">
                <th className="w-[5px] text-center">No</th>
                <th className="w-[200px] text-center">Node</th>
                <th className="w-[80px] text-center">Deselect</th>
              </tr>
            </thead>
            <tbody>
              {recData.map((v, k) => (
                <tr key={k} className="border-1">
                  <td className="text-center">
                    {k + 1}
                  </td>
                  <td className="text-center">
                    {v.name}
                  </td>
                  <td className="text-center">
                    {
                      v.nodeId > 0 && <button name={k} onClick={deselectItem}>Cancel</button>
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex justify-end mt-2 mb-1">
          <input type="text" placeholder="Search Users" value={keyword} className="rounded-md mr-2" onChange={searchUser} />
        </div>
        <div className="flex flex-col">
          {
            mdata.length === 0 ? <div className="flex justify-center">No records</div> :
              <table className="table-auto">
                <thead>
                  <tr className="border-1">
                    <th className="w-[200px] text-center">Node</th>
                    <th className="w-[80px] text-center">Select</th>
                  </tr>
                </thead>
                <tbody>
                  {mdata.filter(v => v.name.toLowerCase().indexOf(keyword.toLowerCase()) >= 0).map((v, k) => (
                    <tr key={k} className="border-1">
                      <td className="text-center">
                        {v.name}
                      </td>
                      <td className="text-center">
                        <input type="checkbox" name={v.id} className={"check-" + v.id} node={v.name} onChange={(e) => clickCheckBox(e)} defaultChecked={checked[v.id]}></input>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
          }
        </div>
      </div>
    </div>
  )
}