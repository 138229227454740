import { useEffect, useState } from "react";
import { useAuth0 } from '@auth0/auth0-react';
import { AddNodeModal } from "../components/AddNodeModal";
import { netMediaApi } from "../services/netMedia";
import { profileApi } from "../services/profile";
import { useDispatch, useSelector } from "react-redux";
import { updatePoints } from '../store/oauth';
import { get_media, get_request, get_videos } from "../store/media";
import { AddTokenModal } from "../components/AddTokenModal";
import { AddVideoModal } from "../components/AddVideoModal";
import { AddVideoReqModal } from "../components/AddVideoReqModal";
import { RequestModal } from "../components/RequestModal";
import { WelcomeModal } from "../components/WelcomeModal";
import { Media } from "../components/Media";
import { Video } from "../components/Video";
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';

export const Home = () => {
  const { isAuthenticated } = useAuth0();

  const dispatch = useDispatch();
  const { medias, requests } = useSelector(state => state.media)
  const { user } = useSelector(state => state.oauth)

  const [signed, setSigned] = useState(false)
  const [addNodeVisible, setAddNodeVisible] = useState(false);
  const [addVideoVisible, setAddVideoVisible] = useState(false);
  const [addTokenVisible, setAddTokenVisible] = useState(false);
  const [addVideoReqVisible, setAddVideoReqVisible] = useState(false);
  const [addRequestVisible, setAddRequestVisible] = useState(false);
  const [welcomeVisible, setWelcomeVisible] = useState(true);
  const [reqNode, setReqNode] = useState();
  const [pin, setPin] = useState();
  const [allNode, setAllNode] = useState();
  const [allNodeList, setAllNodeList] = useState();
  const [resultNode, setResultNode] = useState();
  const [resultNodes, setResultNodes] = useState();
  const [parentNodes, setParentNodes] = useState();
  const [searchKey, setSearchKey] = useState();
  const [searchVideoKey, setSearchVideoKey] = useState('');
  const [resultKey, setResultKey] = useState();
  const [videoList, setVideoList] = useState([]);

  const [loaded, setLoaded] = useState(false);

  // video counts(column 1,2)
  const [count1, setCount1] = useState(0);
  const [count2, setCount2] = useState(0);

  useEffect(() => {

    netMediaApi.getNode().then(res => {
      dispatch(get_media({ medias: res.net_media }))
    })
    netMediaApi.getRequest().then(res => {
      dispatch(get_request({ requests: res.requests }))
    })
    profileApi.getPinned().then((data) => {

      setPin(data);

      var list = [...medias];
      for (var i = 0; i < data.length; i++) {
        for (var j = 0; j < list.length; j++) {
          if (list[j].id == data[i].nodeId) {
            var temp = list[j];
            for (var k = j - 1; k >= i; k--) {
              list[k + 1] = list[k];
            }
            list[i] = temp;
            break
          }
        }
      }

      setAllNodeList(list)
      setAllNode(list)

      setLoaded(true);
    }).catch((err) => {
      console.log(err);
    });

    const url = new URL(window.location.href);
    const myValue = url.searchParams.get('searchKey') || '';
    setSearchKey(myValue);

    const result_key = url.searchParams.get('rk');
    setResultKey(result_key)
    if (result_key !== '' && result_key !== null) {
      const results = result_key.split(',');
      const result_id = results[results.length - 1]
      initClickMedia(result_id)
      // console.log(result_id, medias.filter(item => item.id === result_id)[0])

      // show second node list
      clickMedia(medias.filter(media => media.id === parseInt(result_id))[0], 2)
    }
  }, [dispatch, addNodeVisible])

  const reload = (list) => {

    for (var i = 0; i < pin.length; i++) {
      for (var j = 0; j < list.length; j++) {
        if (list[j].id == pin[i].nodeId) {
          var temp = list[j];
          for (var k = j - 1; k >= i; k--) {
            list[k + 1] = list[k];
          }
          list[i] = temp;
          break
        }
      }
    }

    setAllNodeList(list)

    let searchNodes = list;
    if (searchKey && searchKey.length > 0) {
      searchNodes = list.filter(media => media.name.toUpperCase().indexOf(searchKey.toUpperCase()) > -1)
    }
    setAllNode(searchNodes);

    setResultNode();
    setResultNodes();
    setParentNodes();
    setResultKey('');
    setVideoList([]);
    setCount1(0);
    setCount2(0);
  }

  const initClickMedia = (id) => {
    clickMedia(medias.filter(media => media.id === id)[0], 2)
  }
  const closeModal = () => {
    setAddNodeVisible(false);
    setAddVideoVisible(false);
    setAddTokenVisible(false);
    setAddVideoReqVisible(false);
    setAddRequestVisible(false);
    setWelcomeVisible(false);
  }
  const clickMedia = (media, type) => { //type = 1 Top Nodes, 2: Bottom Nodes
    if (type >= 3) {
      return
    }

    if (media) {
      setResultNodes();
      setParentNodes();

      setResultNode(media);

      //-----------------------------------------//

      // get childs
      if (media.children && media.children.length > 0) {
        const newResult = [];
        media.children.forEach(item => {
          newResult.push(medias.filter(m => m.id === item.id)[0])
        });
        setResultNodes(newResult);
      }

      //-----------------------------------------//

      var url = new URL(window.location.href);
      if (type === 1) {
        var rk = url.searchParams.get('rk');
        if (rk != null) {
          var rks = rk.split(',');
          if (rks.length > 1) {
            if (rks[rks.length - 2] == media.id) {
              rks.pop()
              url.searchParams.set('rk', rks.join(','));
            } else {
              url.searchParams.set('rk', media.id);
            }
          } else {
            url.searchParams.set('rk', media.id);
          }
        } else {
          url.searchParams.set('rk', media.id);
        }
        window.history.pushState({ path: url.href }, '', url.href);
      }
      else {
        let newUrl = url.searchParams.get('rk');
        if (newUrl == null) {
          setResultKey('')
          setVideoList([])
          setCount1(0)
          setCount2(0)
          return
        }

        const index = newUrl.indexOf(media.id);
        if (index === -1) {
          url.searchParams.set('rk', `${newUrl},${media.id}`);
          window.history.pushState({ path: url.href }, '', url.href);
        }
        else {
          newUrl = newUrl.slice(0, index + media.id.toString().length); //,3
          url.searchParams.set('rk', newUrl);
          window.history.pushState({ path: url.href }, '', url.href);
        }
      }

      if (url.searchParams.get('rk')) {
        setResultKey(url.searchParams.get('rk'));
      } else {
        setResultKey('')
      }

      // get parents
      const newResult = [];
      medias.forEach(item => {
        for (var i = 0; i < item.children.length; i++) {
          if (item.children[i].id == media.id) {
            newResult.push(item);
            break;
          }
        }
      });
      var url = new URL(window.location.href);
      var rk = url.searchParams.get('rk');
      if (rk != null) {
        var rks = rk.split(',');
        if (rks.length > 1) {
          var parentId = rks[rks.length - 2];
          for (var k = 0; k < newResult.length; k++) {
            if (newResult[k].id == parentId) {
              var temp = newResult[k];
              newResult[k] = newResult[0];
              newResult[0] = temp;
              break;
            }
          }
        }
      }
      setParentNodes(newResult);

      // remove video list
      setVideoList([])

      netMediaApi.getVideos(media.id).then(res => {
        dispatch(get_videos({ videos: res.videos }))
        setVideoList(signed ? res.videos : []);

        var c1 = 0, c2 = 0;
        for (var i = 0; i < res.videos.length; i++) {
          if (res.videos[i].title.toLowerCase().indexOf(searchVideoKey.toLowerCase()) >= 0) {
            if (res.videos[i].type == 1) {
              c1++;
            } else {
              c2++;
            }
          }
        }

        setCount1(c1);
        setCount2(c2);
      })
    }
  }
  const searchNode = (e) => {
    const searchNodes = allNodeList.filter(media => media.name.toUpperCase().indexOf(e.target.value.toUpperCase()) > -1)
    setAllNode(searchNodes);
    const url = new URL(window.location.href);
    url.searchParams.set('searchKey', e.target.value);
    window.history.pushState({ path: url.href }, '', url.href);
    setSearchKey(e.target.value)
  }
  const handleVideoChange = (e) => {
    setSearchVideoKey(e.target.value);
  }
  const handleVideoList = (videos) => {
    setVideoList(signed ? videos : []);

    var c1 = 0, c2 = 0;
    for (var i = 0; i < videos.length; i++) {
      if (videos[i].title.toLowerCase().indexOf(searchVideoKey.toLowerCase()) >= 0) {
        if (videos[i].type == 1) {
          c1++;
        } else {
          c2++;
        }
      }
    }

    setCount1(c1);
    setCount2(c2);
  }

  useEffect(() => {
    if (signed && resultNode) {
      netMediaApi.getVideos(resultNode.id).then(res => {
        dispatch(get_videos({ videos: res.videos }))
        setVideoList(signed ? res.videos : []);

        var c1 = 0, c2 = 0;
        for (var i = 0; i < res.videos.length; i++) {
          if (res.videos[i].title.toLowerCase().indexOf(searchVideoKey.toLowerCase()) >= 0) {
            if (res.videos[i].type == 1) {
              c1++;
            } else {
              c2++;
            }
          }
        }

        setCount1(c1);
        setCount2(c2);
      })
    }
  }, [signed, resultNode])

  useEffect(() => {

    setSigned(isAuthenticated)

    if (isAuthenticated && user != null) {
      console.log('user.id', user.id);

      netMediaApi
        .clear48HoursLater({ id: user.id })
        .then(res => {
          // Update user info
          dispatch(updatePoints({ points: res.points }))

          // get req list
          netMediaApi.getRequest().then(res => {
            dispatch(get_request({ requests: res.requests }))
          })
        })
    }
  }, [isAuthenticated])

  return (
    <div className="flex flex-col pt-3 min-h-[89vh]">
      <div className="container mx-auto flex flex-col gap-2 mb-4 max-h-">
        <div className="flex justify-end mr-2">
          <Link to="/marketplace" className="cursor-pointer text-decoration-none text-black font-bold">Go To Marketplace →</Link>
        </div>
        {
          signed && user && user.id > 0 && requests && requests.length > 0 && (
            <>
              <div className="py-3 bg-gray-200 flex justify-center text-xl text-black rounded-t-lg">Video Requests</div>
              <div className="flex flex-col overflow-auto max-h-[300px]">
                <table className="table-auto">
                  <thead>
                    <tr>
                      <th className='text-center'>Node</th>
                      <th className='text-center'>Public/User</th>
                      <th className='text-center'>Requester</th>
                      <th className='text-center'>Responsor</th>
                      <th className='text-center'>Tokens</th>
                      <th className='text-center'>Followers</th>
                      <th className='text-center'>Process</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      requests.map((request, key) => (
                        <tr key={key} style={{ borderTop: '1px solid lightgray' }}>
                          <td className='text-center'>
                            {request.title}
                          </td>
                          <td className='text-center'>
                            {request.public == 1 ? 'Public' : 'User'}
                          </td>
                          <td className='text-center'>
                            {request.reqname}
                          </td>
                          <td className='text-center'>
                            {request.public == 1 ? '-' : request.username}
                          </td>
                          <td className='text-center'>
                            {request.public == 1 ? '-' : request.tokens}
                          </td>
                          <td className='text-center'>
                            {
                              (request.public == 0 && user && user.id != request.userid && user.id != request.requester && request.followers.indexOf(user.id) < 0) ?
                                <button className='border-solid border-1 px-2 py-[3px] rounded border-black'
                                  onClick={() => {
                                    setReqNode({
                                      id: request.nodeId,
                                      name: request.title,
                                      public: request.public,
                                      tokens: request.tokens,
                                      requester: request.requester,
                                      responsor: request.userid,
                                    })
                                    setAddTokenVisible(true)
                                  }
                                  }>Add tokens</button>
                                : request.public == 0 ? <div>{request.followers.length}</div> : <div>-</div>
                            }
                          </td>
                          <td className='text-center h-[35px]'>
                            {
                              (request.public == 1 || (user && user.id == request.userid)) &&
                              <button className='border-solid border-1 px-2 py-[3px] rounded border-black'
                                onClick={() => {
                                  setReqNode({
                                    id: request.nodeId,
                                    name: request.title,
                                    public: request.public,
                                    tokens: request.tokens,
                                    requester: request.requester,
                                    responsor: request.userid,
                                  })
                                  setAddVideoReqVisible(true)
                                }
                                }>Upload</button>
                            }
                            {
                              (request.public == 0 && user && user.id == request.requester && request.count > 0) &&
                              <button className='border-solid border-1 px-2 py-[3px] rounded border-danger'
                                onClick={() => {
                                  Swal.fire({
                                    title: 'Do you want to release the tokens?',
                                    icon: 'info',
                                    showCancelButton: true,
                                    confirmButtonText: 'Yes',
                                    cancelButtonText: 'No',
                                  }).then((result) => {
                                    netMediaApi
                                      .releaseTokens({
                                        ...request,
                                        isConfirmed: result.isConfirmed
                                      })
                                      .then(res => {

                                        // Update user info
                                        dispatch(updatePoints({ points: res.points }))

                                        // get req list
                                        netMediaApi.getRequest().then(res => {
                                          dispatch(get_request({ requests: res.requests }))
                                        })

                                        if (resultNode) {
                                          clickMedia(resultNode, 2)
                                        }
                                      })
                                  })
                                }
                                }>Release</button>
                            }
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </>
          )
        }
      </div>
      <div className="container mx-auto" key={2}>
        {/* Show all nodes */}
        <div className="relative">
          <div className="py-3 bg-gray-200 flex justify-center text-xl text-black rounded-t-lg"> Popular Node Choices </div>
        </div>
        <div className="flex justify-between">
          {
            signed ?
              <div className="flex">
                <button className="p-2 bg-gray-300 rounded-md my-1" onClick={() => setAddNodeVisible(true)}>Add New Node</button>
                {
                  user && user.admin == 100 && <div className="flex items-center ml-2">(Branch is removable)</div>
                }
              </div> :
              <div></div>
          }
          <input type="text" placeholder="Search Node" className="rounded-md my-1" onChange={searchNode} />
        </div>
        {
          loaded && allNode && allNode.length > 0 &&
          <div className="overflow-x-scroll px-3 flex flex-row gap-3" onClick={() => clickMedia(allNode[0], 1)} >
            {allNode.map((media, key) => (
              <Media data={media} key={key} clickMedia={clickMedia} reload={reload} setAllNode={setAllNode} type={1} />
            ))}
          </div>
        }

        {/* Show Specific Node */}
        <div className="bg-gray-200 text-black py-3 text-center text-xl mt-3 rounded-t-lg">
          {resultNode ? 'You Selected \"' + resultNode.name + '\"' : 'No Result'}
        </div>

        {
          resultNode &&
          <div className="bg-gray-100 text-black py-1 text-center text-xl">
            Below are moves going to "{resultNode.name}"
          </div>
        }
        {
          parentNodes && parentNodes.length > 0 && <div className="overflow-x-scroll py-2 flex flex-row gap-3" onClick={() => clickMedia(resultNode, 3)} >
            {parentNodes.map((media, key) => (
              <div className="flex flex-col cursor-pointer w-[200 px]" key={key}>
                <Media data={media} key={key} clickMedia={clickMedia} reload={reload} setAllNode={setAllNode} type={1} />
              </div>
            ))}
          </div>
        }

        {
          resultNode &&
          <div className="bg-gray-100 text-black py-1 text-center text-xl">
            Path You Took
          </div>
        }
        {resultKey && resultKey.split(',').length > 0 && (
          <div className=" w-full flex flex-row">
            {resultKey.split(',').map((item, key) => (
              <div
                key={key}
                className="bg-gray-300 py-2 px-3 my-1 rounded-end-5 mr-2"
                onClick={() => clickMedia(medias.filter(media => media.id == item)[0], 2)}
              >
                {medias.filter(media => media.id == item)[0].name + " >>"}
              </div>
            ))}
          </div>
        )}

        {
          resultNode &&
          <div className="bg-gray-100 text-black py-1 text-center text-xl">
            Below are moves you can do next from "{resultNode.name}"
          </div>
        }
        {
          resultNodes && resultNodes.length > 0 &&
          <div className="overflow-x-scroll py-2 flex flex-row gap-3" onClick={() => clickMedia(resultNode, 2)} >
            {resultNodes.map((media, key) => (
              <div className="flex flex-col cursor-pointer w-[200 px]" key={key}>
                <Media data={media} key={key} clickMedia={clickMedia} reload={reload} setAllNode={setAllNode} type={2} />
              </div>
            ))}
          </div>
        }

        {/* Show Video List */}
        <div className="relative">
          <div className="py-3 bg-gray-200 text-black flex justify-center text-xl mt-3 rounded-t-lg">
            {resultNode ? resultNode.name + `'s Video List` : 'No Videos'}
          </div>
        </div>
        <div className="flex justify-between">
          {
            signed && resultNode ?
              <>
                <div className="my-1 flex">
                  <button className="p-2 bg-gray-300 rounded-md" onClick={() => setAddVideoVisible(true)}>Add Video</button>
                  <button className="p-2 bg-gray-300 rounded-md ml-3" onClick={() => setAddRequestVisible(true)}>Add Request</button>
                </div>
                <input type="text" placeholder="Search Video" className="rounded-md my-1" onChange={handleVideoChange} />
              </> :
              <div className="text-lg text-center w-full">
                {
                  signed ? '' : 'Sign in or update your subscription to view the videos'
                }
              </div>
          }
        </div>
        <div className="row my-2 mx-0">
          {signed && count1 > 0 && <div className="h4 text-center bg-gray-200">Instructional</div>}
          {
            videoList.map((v, key) => (
              v.type != 1 || v.title.toLowerCase().indexOf(searchVideoKey.toLowerCase()) < 0 ? <></> : <Video data={v} k={'yy' + key} handleList={handleVideoList} clickMedia={clickMedia} setAllNode={setAllNode} />
            ))
          }
        </div>
        <div className="row my-2 mx-0">
          {signed && count2 > 0 && <div className="h4 text-center bg-gray-200">Live Roll</div>}
          {
            videoList.map((v, key) => (
              v.type != 2 || v.title.toLowerCase().indexOf(searchVideoKey.toLowerCase()) < 0 ? <></> : <Video data={v} k={'xx' + key} handleList={handleVideoList} clickMedia={clickMedia} setAllNode={setAllNode} />
            ))
          }
        </div>
        <div>
          {addNodeVisible && <AddNodeModal visible={addNodeVisible} closeModal={closeModal} setAllNode={setAllNode} clickMedia={clickMedia} defaultValue={null} />}
          {addVideoVisible && <AddVideoModal visible={addVideoVisible} closeModal={closeModal} defaultValue={resultNode.id} node={resultNode.name} clickMedia={clickMedia} />}
          {addTokenVisible && <AddTokenModal visible={addTokenVisible} closeModal={closeModal} node={reqNode} />}
          {addVideoReqVisible && <AddVideoReqModal visible={addVideoReqVisible} closeModal={closeModal} clickMedia={clickMedia}
            nodeid={reqNode.id} node={reqNode.name} public={reqNode.public} tokens={reqNode.tokens}
            requester={reqNode.requester} responsor={reqNode.responsor}
          />}
          {addRequestVisible && <RequestModal visible={addRequestVisible} closeModal={closeModal} defaultValue={resultNode.id} node={resultNode.name} userid={resultNode.userid} />}
          {welcomeVisible && <WelcomeModal visible={welcomeVisible} closeModal={closeModal} />}
        </div>
      </div>
    </div>
  )
}
