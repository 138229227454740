import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
  medias: [],
  requests: [],
  videos: [],
};

export const netMediaSlice = createSlice({
  name: 'media',
  initialState: defaultState,
  reducers: {
    get_media: (state, action) => {
      state.medias = action.payload.medias;
    },
    get_request: (state, action) => {
      state.requests = action.payload.requests;
    },
    get_videos: (state, action) => {
      state.videos = action.payload.videos;
    }
  }
})

export const { get_media, get_request, get_videos } = netMediaSlice.actions;
export const netMediaReducer = netMediaSlice.reducer
