import React, { useState, useEffect } from "react";
import { stripeApi } from "../services/stripe";

export const StripeResult = () => {

  const [sessionId, setSessionId] = useState('');
  const [success, setSuccess] = useState(false);
  const [verification, setVerification] = useState(-1);

  useEffect(() => {
    const url = new URL(window.location.href);
    setSessionId(url.searchParams.get('session_id') || '');
    setSuccess(url.searchParams.get('success') == 'true' || false);
  }, [])

  useEffect(() => {
    if (success == true && sessionId != '') {
      stripeApi.portal({
        session_id: sessionId
      })
        .then(res => {
          if (res.success) {
            setVerification(1);
            return;
          }
          setVerification(0);
        })
        .catch(err => {
          console.log(err);
        })
    }
  }, [sessionId, success])

  return (
    <div className="flex flex-col pt-3 min-h-[89vh] text-black">
      {
        verification === -1 ? (
          <div>
            Waiting...
          </div>
        ) : (
          verification === 1 ? (
            <div className="flex justify-center text-bg-primary">
              Subscription success.
            </div>
          ) : (
            <div className="flex justify-center text-bg-danger">
              Session ID is not correct.
            </div>
          )
        )
      }

      <div className="flex justify-center">
        <button className="m-2 px-3 py-2 border-1 rounded-lg bg-body-secondary" onClick={e => location.href='/'}>Return</button>
      </div>
    </div>
  )
}
