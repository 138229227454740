import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';


import autoMergeLevel1 from 'redux-persist/es/stateReconciler/autoMergeLevel1';
import { oauthReducer } from './oauth';
import { netMediaReducer } from './media'

const persistConfig = {
    key: 'airplain-booking',
    storage,
    stateReconciler: autoMergeLevel1,
    // blacklist: ['cmsHome', 'cmsclaim', 'stopDelay']
};
const reducers = combineReducers({
    oauth: oauthReducer,
    media: netMediaReducer,
});
const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk],
})
export const persistor = persistStore(store)
