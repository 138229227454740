import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { profileApi } from "../services/profile";
import { updateRequests } from "../store/oauth";

export const BeltRequest = () => {

  const dispatch = useDispatch();

  const userData = useSelector(state => state.oauth.user);

  const handleClick = (data) => {
    profileApi.verifyUser(data).then((data) => {
      dispatch(
        updateRequests(
          { requests: data }
        )
      );
    }).catch((err) => {
      console.log(err);
    });
  }

  return (
    <div className="flex flex-col pt-3 min-h-[89vh]" key={2}>
      <div className="container flex justify-center">
        {
          userData &&
          (
            userData.requests.length == 0 ?
              <div className="flex justify-center">No records</div> :
              <table className="table-auto">
                <thead>
                  <tr>
                    <th className="w-[200px] text-center">Username</th>
                    <th className="w-[100px] text-center">Belt</th>
                    <th className="w-[100px] text-center">Verify</th>
                  </tr>
                </thead>
                <tbody>
                  {userData.requests.map((v, k) => (
                    <tr key={k}>
                      <td className="text-center">
                        {v.username}
                      </td>
                      <td>
                        <div className="w-full flex justify-center">
                          {v.belt === 1 && <img className="h-6" src="/Belt_White.svg" alt="White Belt" />}
                          {v.belt === 2 && <img className="h-6" src="/Belt_Blue.svg" alt="Blue Belt" />}
                          {v.belt === 3 && <img className="h-6" src="/Belt_Purple.svg" alt="Purple Belt" />}
                          {v.belt === 4 && <img className="h-6" src="/Belt_Brown.svg" alt="Brown Belt" />}
                          {v.belt === 5 && <img className="h-6" src="/Belt_Black.svg" alt="Black Belt" />}
                        </div>
                      </td>
                      <td>
                        <div className="w-full flex justify-center">
                          <button className="p-2 bg-gray-600 text-white rounded-md" onClick={() => handleClick(v)}>OK</button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
          )
        }
      </div>
    </div>
  )
}