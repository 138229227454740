import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import { netMediaApi } from '../services/netMedia';
import { updatePoints } from '../store/oauth';
import Swal from 'sweetalert2';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

export const AddVideoModal = (props) => {

  const dispatch = new useDispatch();
  const { medias } = useSelector(state => state.media)
  const { user } = useSelector(state => state.oauth)

  const [data, setData] = useState({
    node: '',
    name: '',
    type: 1,
    url: '',
    description: ''
  });
  const [video, setVideo] = useState(null);
  const [videoState, setVideoState] = useState(1);
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    if (loading) {
      return;
    }
    props.closeModal();
  }

  useEffect(() => {
    props.defaultValue && setData({ ...data, node: props.defaultValue });
  }, [props])

  const onFormSubmit = (e) => {
    e.preventDefault();
    if (data.name !== '') {

      if (video == null) {
        Swal.fire({
          title: 'Please choose a file.',
          icon: 'error'
        });
        return;
      }

      // videoState: 1-file, 2-url
      if (videoState == 1) {
        const formData = new FormData();
        formData.append("type", data.type);
        formData.append("name", data.name);
        formData.append("video", video); // custom file
        formData.append('userid', user.id);
        formData.append('belt', user.belt);
        formData.append("description", data.description);
        formData.append('nodeId', props.defaultValue ? props.defaultValue : 0);

        setLoading(true);
        netMediaApi.addVideo1(formData).then(res => {
          if (res) {
            // dispatch(updatePoints({ points: res.user.points }))

            const id = props.defaultValue ? props.defaultValue : 0;
            const media = medias.filter(media => media.id === id)[0]
            props.clickMedia(media, 2)
            props.closeModal()
            setLoading(false)
          }
        }).catch(e => {
          Swal.fire({
            title: e,
            icon: 'error'
          })
        });
      } else {
        const fdata = {
          type: data.type,
          name: data.name,
          url: data.url, // youtube url
          userid: user.id,
          belt: user.belt,
          description: data.description,
          nodeId: props.defaultValue ? props.defaultValue : 0
        };

        netMediaApi.addVideo2(fdata).then(res => {
          if (res) {
            const id = props.defaultValue ? props.defaultValue : 0;
            const media = medias.filter(media => media.id === id)[0]
            props.clickMedia(media, 2)
            props.closeModal()
            setLoading(false)
          }
        })
      }
    }
    else {
      Swal.fire({
        title: 'Please input value.',
        icon: 'error'
      });
    }
  }
  const changeHandle = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    })
  }

  return (
    <div>
      <Modal
        isOpen={props.visible}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        {loading ?
          <div className='flex justify-center items-center'>
            Uploading...
          </div>
          :
          <div>
            <button onClick={closeModal} className='float-right text-2xl'>&times;</button>
            <p className='text-xl text-center mt-3'> New Video </p>
            <form className='py-3 mt-5 w-96 flex flex-col' onSubmit={onFormSubmit}>
              <div className="flex p-2 w-full text-bg-primary justify-center mb-3">
                {props.node}
              </div>
              <div className='w-full flex justify-between px-12'>
                <div>
                  <input type='radio' name='type_radio' onClick={() => setData({ ...data, type: 1 })} defaultChecked={data.type === 1} className="relative t-3" />
                  &nbsp;Instructional
                </div>
                <div>
                  <input type='radio' name='type_radio' onClick={() => setData({ ...data, type: 2 })} defaultChecked={data.type === 2} className="relative t-3" />
                  &nbsp;Live Roll
                </div>
              </div>
              <div className="flex p-2">
                <input type='text' name='name' onChange={changeHandle} className="flex w-full h-2/5" placeholder='Title' />
              </div>
              <div className='w-full flex justify-between px-12'>
                <div><input type='radio' name='url_radio' onClick={() => setVideoState(1)} defaultChecked={videoState === 1} className="relative t-3" /> Custom File</div>
                <div><input type='radio' name='url_radio' onClick={() => setVideoState(2)} defaultChecked={videoState === 2} className="relative t-3" /> Youtube Url</div>
              </div>
              {
                videoState === 2 ?
                  <div className="flex p-2">
                    <input type='text' name='url' onChange={changeHandle} className="flex w-full h-2/5" placeholder='Youtube Url' />
                  </div> :
                  <div className="flex p-2">
                    <input type='file' name='video' accept='video/*' onChange={e => setVideo(e.target.files[0])} className="flex w-full h-2/5" />
                  </div>
              }
              <div className="flex p-2 w-full">
                <textarea name='description' onChange={changeHandle} className="flex w-full h-32" placeholder='Description' />
              </div>
              <button className="flex p-3 mt-5 mx-5 bg-green-400 rounded-lg justify-center">Save</button>
            </form>
          </div>
        }
      </Modal>
    </div>
  );
}
