import React, { useState } from "react";
import Select from 'react-select';
import { belt_json } from "../consts";
import { useSelector } from "react-redux";
import { profileApi } from "../services/profile";
import { useEffect } from "react";
import Swal from 'sweetalert2';

export const Profile = () => {

  const userData = useSelector(state => state.oauth.user);

  const [option, setOption] = useState([]);
  const [belt, setBelt] = useState();
  const [beltVerified, setBeltVerified] = useState(false);
  const [verifierName, setVerifierName] = useState('');
  const [verifierEmail, setVerifierEmail] = useState('');
  const [verifiers, setVerifiers] = useState([]);

  const requestBelt = () => {
    if (verifierEmail.length > 0) {
      profileApi.changeBelt(userData.email, belt.value, verifierEmail).then((data) => {
        if (data.success === true) {
          console.log(data.msg);
          // toast.success
          Swal.fire({
            title: 'Your request has been sent to @' + verifierName + '. Your request will be reviewed shortly.',
            icon: 'success'
          })
        } else {
          console.log(data.msg);
          // toast.error
        }
      }).catch((err) => {
        console.log(err);
      });
    } else {
      // toast.info
    }
  }

  const handleChange = (e) => {
    setVerifierName(e.value)
    setVerifierEmail(e.email)
  }

  React.useEffect(() => {
    const op = [];
    for (let i in belt_json) {
      op.push({ value: 5 - i, image: `Belt_${belt_json[i]}.svg` });
    }
    setOption(op);
    setBelt(op[0])
  }, []);

  useEffect(() => {
    if (!userData) { return }

    profileApi.getVerifiers(userData.belt).then((data) => {
      const res = []
      for (let x in data) {
        res.push({ value: data[x].username, email: data[x].email })
      }
      setVerifiers(res)
    })
  }, [userData])

  return (
    <div className="flex flex-col pt-3 min-h-[89vh] text-black" key={2}>
      <div className="container mx-auto flex justify-center items-center gap-10 mb-6">
        <div>My Level:</div>
        <Select
          value={belt}
          className="w-28 bg-gray-300 focus:border-none"
          options={option}
          formatOptionLabel={op => {
            return (
              <div className="py-1 flex  justify-center"
                onClick={() => {
                  setBelt(op)
                  setBeltVerified(userData.belt >= op.value)
                }}>
                <img src={op.image} alt="country" className={`h-6 ${userData.belt < op.value ? '' : 'opacity-50'}`} />
              </div>
            )
          }}
          isSearchable={false}
        />
      </div>

      <div className="container mx-auto flex justify-center items-center gap-10 mb-6">
        <div>Verifier:</div>
        <Select
          className="w-28 bg-gray-300 focus:border-none text-black"
          options={verifiers}
          formatOptionLabel={op => {
            return (
              <div className="py-1"
                onClick={() => {
                  setVerifierEmail(op.email)
                }}>
                {op.value}
              </div>
            )
          }}
          isSearchable={true}
          onChange={handleChange}
        />
      </div>

      <div className="flex justify-center gap-4">
        {beltVerified ? (
          <span className="p-2 bg-gray-600 text-white text-xl rounded-lg text-black">Verified</span>
        ) : (
          <button className="p-2 bg-gray-600 text-white text-xl rounded-lg text-black" onClick={requestBelt}>Request</button>
        )}
      </div>
    </div>
  )
}