import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
  token: "",
  user: {
    id: 0,
    username: "",
    email: "",
    belt: 0,
    verifier: 0,
    admin: 0,
    points: 0,
    total: 0, // total tokens
    subscriptionDate: '',
    DFLAG: 1, // Subscription - INACTIVE(1), ACTIVE(0)
    requests: [], // belt request
    market: { tokens: 0, price: 0 }, // my marketplace
    marketList: [], // market list
  },
  isLogin: false,
};
export const oauthSlice = createSlice({
  name: 'oauth',
  initialState: defaultState,
  reducers: {
    login: (state, action) => {
      state.user = action.payload;
      if (action.payload.id === undefined) {
        state.isLogin = false;
      } else {
        state.isLogin = true;
      }
    },
    log_out: (state, action) => {
      state.user = null;
      state.isLogin = false;
      state.token = "";
    },
    updateName: (state, action) => {
      state.user.username = action.payload.username;
    },
    updatePoints: (state, action) => {
      state.user.points = action.payload.points;
    },
    updateTotal: (state, action) => {
      state.user.total = action.payload.total;
    },
    updateDFlag: (state, action) => {
      state.user.DFLAG = action.payload.DFLAG;
    },
    updateSubscriptiondate: (state, action) => {
      state.user.subscriptionDate = action.payload.subscriptionDate;
    },
    updateRequests: (state, action) => {
      state.user.requests = action.payload.requests;
    },
    updateMyMarketInfo: (state, action) => {
      state.user.market = action.payload.market;
    },
    updateMarketList: (state, action) => {
      state.user.marketList = action.payload.list;
    },
  }
})
export const { login, log_out, updateName, updatePoints, updateTotal, updateDFlag, updateSubscriptiondate, updateRequests, updateMyMarketInfo, updateMarketList } = oauthSlice.actions;
export const oauthReducer = oauthSlice.reducer