import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { netMediaApi } from '../services/netMedia';
import { useDispatch, useSelector } from 'react-redux';
import { get_media } from '../store/media';
import Swal from 'sweetalert2';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

Modal.setAppElement('#root');

export const EditNodeModal = (props) => {
  const dispatch = new useDispatch();

  const { medias } = useSelector(state => state.media)

  const [data, setData] = useState({
    name: '',
    position: 1,
    request: false,
    description: ''
  });
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    if (loading) {
      return;
    }
    props.closeModal();
  }

  useEffect(() => {
    setData({
      ...data,
      name: props.data.name,
      position: props.data.position,
    });
  }, [props]);

  useEffect(() => {
    const url = new URL(window.location.href);
    const result_key = url.searchParams.get('rk');
    if (result_key) {
      const results = result_key.split(',');
      const id = results[results.length - 1]
      for (var i = 0; i < medias.length; i++) {
        if (medias[i].id == id) {
          // console.log('in', medias[i])
          props.clickMedia(medias[i], 2)
          break
        }
      }
    }
  }, [medias])

  const onFormSubmit = (e) => {
    e.preventDefault();

    if (data.name !== '') {

      if (image == null) {

        const sendData = {
          nodeid: props.data.id,
          name: data.name,
          position: data.position,
          request: data.request,
          description: data.description,
        };

        netMediaApi.editNode2(sendData).then(res => {
          if (res) {

            dispatch(get_media({ medias: res.net_media }))
            props.closeModal()
            props.setAllNode(res.net_media);

            const url = new URL(window.location.href);
            const result_key = url.searchParams.get('rk');
            if (result_key) {
              const results = result_key.split(',');
              const id = results[results.length - 1]
              for (var i = 0; i < res.net_media.length; i++) {
                if (res.net_media[i].id == id) {
                  // console.log('in', res.net_media[i])
                  props.clickMedia(res.net_media[i], 2)
                  break
                }
              }
            }
          }
        })
      } else {
        let formData = new FormData();
        formData.append("nodeid", props.data.id);
        formData.append("name", data.name);
        formData.append("image", image);
        formData.append("position", data.position);
        formData.append("request", data.request);
        formData.append("description", data.description);

        setLoading(true)
        netMediaApi.editNode(formData).then(res => {
          if (res) {
            dispatch(get_media({ medias: res.net_media }))
            props.closeModal()
            setLoading(false)
            props.setAllNode(res.net_media);

            const url = new URL(window.location.href);
            const result_key = url.searchParams.get('rk');
            if (result_key) {
              const results = result_key.split(',');
              const id = results[results.length - 1]
              for (var i = 0; i < res.net_media.length; i++) {
                if (res.net_media[i].id == id) {
                  // console.log('in', res.net_media[i])
                  props.clickMedia(res.net_media[i], 2)
                  break
                }
              }
            }
          }
        })
      }
    }
    else {
      Swal.fire({
        title: 'Please input value.',
        icon: 'error'
      });
    }
  }

  const changeHandle = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    })
  }
  const handleCheckboxChange = (event) => {
    console.log(data);
    setData({ ...data, request: event.target.checked });
  }

  return (
    <div>
      <Modal
        isOpen={props.visible}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        {loading ?
          <div className='flex justify-center items-center'>Uploading...</div> :
          <div>
            <button onClick={closeModal} className='float-right text-2xl'>&times;</button>
            <p className='text-xl text-center mt-3'> Edit Node </p>
            <form className='py-5 w-96 flex flex-col' method="POST" onSubmit={onFormSubmit}>
              <div className="flex p-2">
                <input type='text' name='name' onChange={changeHandle} value={data.name} className="flex w-full h-2/5" placeholder='Name' />
              </div>
              <div className="flex p-2">
                <select type='text' name='position' defaultValue={data.position} value={data.position} onChange={changeHandle} className="flex w-full h-2/5">
                  <option value={1} key={1}>Top</option>
                  <option value={2} key={2}>Bottom</option>
                  <option value={3} key={3}>Neutral</option>
                </select>
              </div>
              <div className="flex p-2 w-full">
                <input type='file' name='image' accept='image/*' onChange={e => setImage(e.target.files[0])} className="flex w-full h-2/5" placeholder='Image' />
              </div>
              <div className='flex p-2 w-full gap-3'>
                <input type='checkbox' className='mt-2' checked={data.request} onChange={handleCheckboxChange} /> Requirement
              </div>
              {data.request &&
                <div className="flex p-2 w-full">
                  <textarea name='description' onChange={changeHandle} className="flex w-full h-32" placeholder='Enter Description' />
                </div>}
              <button className="flex p-3 mt-5 mx-5 bg-green-400 rounded-lg justify-center">Save</button>
            </form>
          </div>}
      </Modal>
    </div>
  );
}