import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { profileApi } from "../services/profile";
import { updateName } from "../store/oauth";

export const ChangeName = () => {

  const dispatch = useDispatch();

  const userData = useSelector(state => state.oauth.user);

  const [username, setUsername] = useState('');

  const update = () => {
    if (username.length == 0) {
      return
    }

    profileApi.changeName(userData.email, username).then((data) => {
      dispatch(
        updateName(
          {username}
        )
      );
    }).catch((err) => {
      console.log(err);
    });
  }

  const handleChange = (e) => {
    setUsername(e.target.value);
  }

  return (
    <div className="flex flex-col pt-3 min-h-[89vh]" key={2}>
      <div className="container mx-auto flex justify-center items-center gap-10 mb-6">
        <input
          className="p-2 border-1 border-solid border-gray-600 rounded-2"
          placeholder="Type your new name."
          onChange={(e) => handleChange(e)}
        ></input>
      </div>

      <div className="flex justify-center gap-4">
        <button className="p-2 bg-gray-600 text-white text-xl rounded-lg" onClick={update}>Change</button>
      </div>
    </div>
  )
}