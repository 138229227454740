import axios from "axios";
import { SERVER_URL } from "../consts";

class StripePayment {
  checkout(data) {
    return new Promise((resolve, reject) => {
      axios.post(`${SERVER_URL}/stripe/checkout-session`, data)
        .then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
    });
  }
  portal(data) {
    return new Promise((resolve, reject) => {
      axios.post(`${SERVER_URL}/stripe/portal-session`, data)
        .then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
    });
  }
  webhook(data) {
    return new Promise((resolve, reject) => {
      axios.post(`${SERVER_URL}/stripe/webhook`, data)
        .then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
    });
  }
}

export const stripeApi = new StripePayment();
